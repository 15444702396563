import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {AuthService} from './auth/auth.service';
import {WfirmaMessageType} from '../common/enums/wfirma-message-type';
import {UserStoreService} from './user-store.service';
import {ApplicationState} from '../common/enums/application-state';
import {ApplicationStateService} from './application-state.service';
import {ErrorType} from '../common/enums/error-type';
import {AppError} from '../common/classes/app-error';
import {ReplaySubject, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfigStyleService} from './config-style.service';

@Injectable({
    providedIn: 'root'
})
export class WfirmaCommunicationService {

    colorMode$ = new ReplaySubject(1);
    companyData$: Subject<any> = new Subject();
    companyId = 0;
    anonymous: boolean = false;

    constructor(
        private authService: AuthService,
        private userStoreService: UserStoreService,
        private applicationStateService: ApplicationStateService,
        private _configStyleService: ConfigStyleService,
        public translate: TranslateService
    ) {
        this.setMessageEventListener();

        // sprawdzenie domeny hosta tymczasowo zakomentowane
        // if (!this.authService.getUserId() && !this.anonymous) {
        //     this.sendMessageToParent(WfirmaMessageType.LOGIN);
        // }

        // Do testowania lokalnego
        // if (!environment.production && !environment.staging) {
        //     this.authService.login({login: environment.testLogin, password: environment.testPassword});
        // }
    }

    private setMessageEventListener() {
        window.onmessage = (message: MessageEvent) => {
            // sprawdzenie domeny hosta tymczasowo zakomentowane
            /* if (environment.production && message.origin !== environment.iframeParentDomain) {
                return;
            }*/

            if (!message.data) {
                return;
            }

            try {
                const messageBody = JSON.parse(message.data);

                this.handleMessage(messageBody);
            } catch (e) {
                if (environment.staging) {
                    console.error(e);
                }
            }
        };
    }

    private handleMessage(message) {
        if (!message.type.includes('wfirma-') && !message.type.includes('chat-')) {
            return;
        }

        switch (message.type) {
            case WfirmaMessageType.LOGIN:
                if (!message.data.login || !message.data.password) {
                    this.applicationStateService.setError(new AppError(ErrorType.apiLogin, this.translate.instant('CAPTIONS.LOGIN-ERROR'), null));
                    this.sendMessageToParent(WfirmaMessageType.SEND_ERROR, 'Brak loginu lub hasła');

                    return;
                }

                this.applicationStateService.setState(ApplicationState.LOADING);

                if (message.data.external_id) {
                    this.companyId = +message.data.external_id;
                }

                if (message.data.mode) {
                    this.colorMode$.next(message.data.mode);
                }

                this.authService.login(message.data);

                break;
            case WfirmaMessageType.COLOR_MODE:
                if (message.data.mode) {
                    this.colorMode$.next(message.data.mode);
                }

                break;
            case WfirmaMessageType.LOGOUT:
                this.applicationStateService.setState(ApplicationState.READY);
                this.authService.logout();

                break;
            case WfirmaMessageType.COMPANY_DATA:
                this.companyData$.next(message.data);

                break;
            case WfirmaMessageType.CREATE_NEW_CHAT_CLIENT:
                this.companyId = 0;
                this.anonymous = true;

                this.applicationStateService.customerData
                    .mutate((state: { client_id: string, customer_id: string, external_hash: string }): void => {
                        state.client_id = message.data.client_id;
                        state.customer_id = message.data.customer_id;
                        state.external_hash = message.data.external_hash;
                    });

                break;
            case WfirmaMessageType.WIDGET_SETTINGS:
                this._configStyleService.setConfig(message.data);
                break;
            default:
                this.sendMessageToParent(WfirmaMessageType.SEND_ERROR, 'Wywołanie ze strony hosta ma nieznany typ');
        }
    }

    sendMessageToParent(messageType: WfirmaMessageType, message = '') {
        const data = {
            type: messageType,
            data: message
        };

        environment.iframeParentDomain
            ? window.parent.postMessage(JSON.stringify(data), environment.iframeParentDomain)
            : window.parent.postMessage(JSON.stringify(data), '*');
    }

    // Pobranie danych firmy z wFirmy
    getCompanyData() {
        if (!this.companyId) {
            return false;
        }

        this.sendMessageToParent(WfirmaMessageType.SEND_GET_COMPANY_DATA);
    }
}
