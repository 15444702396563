import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-error-404',
    templateUrl: './error-404.component.html',
    styleUrls: ['./error-404.component.scss']
})
export class Error404Component {

    constructor() {
    }
}
