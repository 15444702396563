function activateLinksInLine(line: string) {
    const result = line.split(' ').map((word: string) => word.includes('http://') || word.includes('https://')
        ? `<a href="${word}" target="_blank" rel="noopener">${word}</a>`
        : word
    );

    return result.join(' ');
}

export function activateLinks(body: string): string {
    if (!body) {
        return body;
    }

    const linesArr = body.split('\n').filter(line => !!line.trim()),
        linesWithLinksArr = linesArr.map(line => activateLinksInLine(line));

    return linesWithLinksArr.join('<br>');
}
