import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { WidgetInterface } from '../common/interfaces/widget.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigStyleService {

  private _configStyle = new BehaviorSubject<WidgetInterface | null>({settings: null, widgetType: null, screenWidth: null, screenHeight: null} );

  constructor() { }

  public getConfig$(): Observable<WidgetInterface | null> {
    return this._configStyle.asObservable();
  }

  public setConfig(config: WidgetInterface): void {
        this._configStyle.next(config);
  }
}
