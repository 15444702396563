<div class="chat-toolbar">
    <hr *ngIf="!isMobile" [ngStyle]="{'border-color': styleConfig.content.placeholderTextColor}">
    <div class="chat-toolbar__input-box"
         [ngStyle]="{'background-color': styleConfig.actions.textAreaBackground}"
         [ngClass]="{'dark-mode': styleConfig.theme === darkTheme, 'contrast-mode': styleConfig.theme === contrastTheme}">
        <textarea *ngIf="!isMobile"
            [ngStyle]="{'color': styleConfig.content.supportColor, 'font-size': '16px'}"
            class="chat-toolbar__textarea"
            [ngClass]="getTextareaClasses()"
            placeholder="{{'CAPTIONS.WRITE-MESSAGE' | translate}}&hellip;"
            [(ngModel)]="text"
            (keydown)="onInputKeyDown()">
        </textarea>
        <textarea *ngIf="isMobile"
                  [ngStyle]="{'color': styleConfig.content.supportColor, 'font-size': '16px'}"
                  class="chat-toolbar__textarea"
                  [ngClass]="getTextareaClasses()"
                  placeholder="{{'CAPTIONS.WRITE-MESSAGE' | translate}}&hellip;"
                  [(ngModel)]="text"
                  (keydown)="onInputKeyDown()"
                  matInput
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  [cdkAutosizeMaxRows]="customMaxRows">
        </textarea>
        <button
             [ngClass]="styleConfig.theme === darkTheme ? 'chat-toolbar__icon-dark-mode' : 'chat-toolbar__icon-light-mode'"
             [ngStyle]="{'background-color': styleConfig.actions.textAreaBackground,
             'border-color': styleConfig.content.placeholderTextColor}"
             (click)="toggleExpand()">
             <mat-icon [ngStyle]="{'color': styleConfig.actions.buttonBackground}">
                 {{expand ? 'expand_more' : 'expand_less'}}
             </mat-icon>
        </button>
    </div>
    <hr *ngIf="!isMobile" [ngStyle]="{'border-color': styleConfig.content.placeholderTextColor}">
    <div class="chat-toolbar__toolbar"
         [ngStyle]="{'background-color': styleConfig.actions.textAreaBackground}">
<!--        <button-->
<!--            class="chat-toolbar__close"-->
<!--            mat-icon-button-->
<!--            matTooltip="{{ 'CAPTIONS.CLOSE-THREAD' | translate }}"-->
<!--            [matTooltipDisabled]="!currentThread"-->
<!--            [disabled]="!enabled || !currentThread"-->
<!--            (click)="closeCurrentThread()"-->
<!--        >-->
<!--            <mat-icon class="toolbar-icon">subtitles_off</mat-icon>-->
<!--        </button>-->
        <div class="chat-toolbar__attachment">
            <app-file-uploader
                [styleConfig]="styleConfig"
                *ngIf="fileUploaderVisible"
                [threadId]="currentThread?.id"
                [enabled]="enabled"
                (refresh)="onUploadRefresh()"
            >
            </app-file-uploader>
        </div>
        <button [ngClass]="{
            'chat-toolbar__send-dark-mode': styleConfig.theme === darkTheme,
            'chat-toolbar__send-light-mode': styleConfig.theme !== darkTheme,
            'enabled': enabled && text,
            'disabled': !enabled || !text}"
            [ngStyle]="{'color': styleConfig.actions.buttonBackground,
            'font-size': styleConfig.actions.fontSizeButton + 'px',
            'background-color': styleConfig.actions.textAreaBackground,
            'margin-right': isMobile ? '25px' : ''}"
            (click)="sendMessage()">
            <mat-icon [ngStyle]="{'color': styleConfig.actions.buttonBackground}">send</mat-icon>
        </button>
    </div>
</div>
