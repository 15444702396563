import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-chat-message-typing',
    templateUrl: './chat-message-typing.component.html',
    styleUrls: [
        './chat-message-typing.component.scss',
        '../chat-message/chat-message.component.scss'
    ]
})
export class ChatMessageTypingComponent {

    @Input() senderAvatar: string;

}
