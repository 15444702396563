export const configMockup = {
    name: 'https://test.pl',
    uid: '86_653a50fe4b20b1.90657347',
    theme: 'light',
    id: 67,
    header: {
        background: '#F6F8FA',
        formLabel: 'Czat',
        chatLabel: 'Formularz',
        iconColor: '#0360AA',
        labelColor: '#000000',
        labelSize: 17,
        formSubtitle: '',
        formLabelColor: '#000000',
        formLabelFontSize: 22,
        formSubtitleColor: '#000000',
        formSubtitleFontSize: 18,
        textAlign: 'center'
    },
    content: {
        textareaBorderColor: '#015faa',
        placeholderText: '',
        backgroundColor: '#ffffff',
        supportBackground: '#ffffff',
        clientBackground: '#E9F0F5',
        contentBackground: '#F6F8FA',
        fontSize: 14,
        supportColor: '#000000',
        clientColor: '#000000',
        termsAndConditionsLink: '',
        privacyStatementLink: '',
        inputsTextColor: '#000000',
        inputsBorderColor: '#015faa',
        formLinksAndCloseIconColor: '#015faa',
        placeholderTextColor: '#C1C1C1',
        formBackground: '#ffffff',
        customUserTerms: []
    },
    actions: {
        defaultButtonBackground: 'linear-gradient(to right, #309a00, #3cc300)',
        buttonBackground: '#005FAA',
        fontSizeButton: 16,
        attachmentIconColor: '#C1C1C1',
        actionsBackground: '#b9b9b9',
        textAreaBackground: '#F6F8FA',
        buttonLabelColor: '#ffffff',
        showAttachmentIcon: true,
        showPhoneInput: true,
        sendBtnLabelColor: '#ffffff',
        showAdditionalTerm: false
    },
    button: {
        color: '#ffffff',
        background: '#005FAA',
        positionOnPage: {
            right: 1.5,
            top: 93,
        },
        radius: 20,
        size: 60,
        fontSize: 30,
        iconSvg: 'chat_bubble',
        formIconSvg: 'description'
    },
    onInit: {
        requiredSetFirstName: true,
        requiredRegister: false,
        logo: '',
        text: '',
        buttonBackground: '#309a00',
        buttonColorText: '#ffffff',
        buttonFontSize: 17,
        fontText: 17,
        textColor: '#000000',
        autoOpenChat: false,
        autoOpenChatDelay: 0,
        showTooltipMessage: false,
        tooltipMessageDelay: 500,
        tooltipMessageText: '',
        tooltipBackground: '#005FAA',
        tooltipTextColor: '#ffffff',
        inputPlaceholder: '',
        buttonLabel: '',
        footerBackground: '#E2E8ED'
    }
};
