import {Component, Input, OnChanges} from '@angular/core';
import saveAs from 'file-saver';
import {ChatMessageComponent} from '../chat-message/chat-message.component';
import {UserStoreService} from '../../services/user-store.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DataService} from '../../services/api/data.service';
import {ApplicationStateService} from '../../services/application-state.service';
import {AppError} from '../../common/classes/app-error';
import {ErrorType} from '../../common/enums/error-type';
import {MessageSendingStatus} from '../../common/enums/message-sending-status';
import {TranslateService} from '@ngx-translate/core';
import {WidgetSettingsInterface} from "../../common/interfaces/widget.interface";
import {ChatTheme} from "../../common/enums/chat-theme";
@Component({
    selector: 'app-chat-message-file',
    templateUrl: './chat-message-file.component.html',
    styleUrls: [
        '../chat-message/chat-message.component.scss',
        './chat-message-file.component.scss'
    ]
})
export class ChatMessageFileComponent extends ChatMessageComponent {

    @Input() declare styleConfig: WidgetSettingsInterface;

    disabled = false;
    iconType = 'attachment';
    sendingStatus = MessageSendingStatus;
    contrastTheme = ChatTheme.contrast;

    constructor(
        userStoreService: UserStoreService,
        sanitizer: DomSanitizer,
        private dataService: DataService,
        private applicationStateService: ApplicationStateService,
        public translate: TranslateService
    ) {
        super(userStoreService, sanitizer, translate);
    }

    private setDisabledState() {
        this.disabled = true;
        this.applicationStateService.infoErrorTimeout$.asObservable().subscribe(() => this.disabled = false);
    }

    private setDownloadError(message: string, err: Error) {
        this.applicationStateService.setInfoError(new AppError(ErrorType.apiGet, message, err));
    }

    downloadAttachment() {
        this.setDisabledState();

        this.dataService.downloadAttachment(+this.message.fileId).subscribe(result => {
                try {
                    saveAs(new Blob([result.body], {type: this.message.fileType}), this.message.message);
                    this.disabled = false;
                } catch (err) {
                    this.setDownloadError(this.translate.instant('CHAT-MESSAGE-FILE.DOWNLOAD-FILE-ERROR'), err);
                }
            },
            err => {
                const message = err.status === 403
                    ? this.translate.instant('CHAT-MESSAGE-FILE.FILE-SCAN-TRY-AGAIN')
                    : this.translate.instant('CHAT-MESSAGE-FILE.DOWNLOAD-FILE-ERROR');

                this.setDownloadError(message, err);
            }
        );
    }
}
