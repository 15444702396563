<div
    *ngIf="threadsWithMessages.length;"
    class="chat-list" #messageList
    [ngClass]="{'chat-list-mobile': isMobile}"
    (scroll)="onScroll($event)">
    <ng-container *ngFor="let thread of threadsWithMessages; let isLastThread = last">
        <div class="chat-list__thread">
            <div class="chat-list__thread-date" [ngClass]="styleConfig.theme === darkTheme ? 'dark-mode' : ''">{{thread.dateCreated | date : 'yyyy-MM-dd '}}</div>
            <ng-container *ngFor="let message of thread.messages; let isLastMessage = last">
                <app-chat-message
                    *ngIf="message.type === messageType.text"
                    [message]="message"
                    [styleConfig]="styleConfig"
                    [threadOwnerId]="thread.ownerId"
                    [isLast]="isLastMessage"
                    (retryMessage)="retryMessage.emit($event)"
                    (senderAvatar)="setUserAvatar($event)"
                ></app-chat-message>
                <app-chat-message-file
                    [styleConfig]="styleConfig"
                    *ngIf="message.type === messageType.file"
                    [message]="message"
                    [threadOwnerId]="thread.ownerId"
                    [isLast]="isLastMessage"
                ></app-chat-message-file>
            </ng-container>
            <app-chat-message-typing
                *ngIf="isLastThread && thread.status !== threadStatus.closed && employeeTyping"
                [senderAvatar]="currentAvatar">
            </app-chat-message-typing>
        </div>

        <div *ngIf="thread.status == threadStatus.closed" class="horizontal-ruler">
            <span class="absolute-center">{{ 'CAPTIONS.THREAD-CLOSED' | translate }}</span>
        </div>
    </ng-container>
</div>

