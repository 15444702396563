import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'extractText'
})
export class ExtractLinkInfoPipe implements PipeTransform {
        transform(value: string, startMarker: string, endMarker: string): string | null {
            const startIdx = value.indexOf(startMarker);
            const endIdx = value.indexOf(endMarker);

            if (startIdx !== -1 && endIdx !== -1) {
                return value.substring(startIdx + startMarker.length, endIdx).trim();
            }

            return null;
        }
}
