import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ApplicationStateService} from '../../services/application-state.service';
import {AppError} from '../../common/classes/app-error';
import {ErrorType} from '../../common/enums/error-type';
import {ColorModeService} from '../../services/color-mode.service';
import {SettingsService} from '../../services/settings.service';
import {WidgetSettingsInterface} from 'src/app/common/interfaces/widget.interface';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationTranslateService} from 'src/app/services/application-translate.service';
import {ChatTheme} from "../../common/enums/chat-theme";


@Component({
  selector: 'app-welcome-box',
  templateUrl: './welcome-box.component.html',
  styleUrls: ['./welcome-box.component.scss']
})
export class WelcomeBoxComponent {

  @Input() userName: string;
  @Input() promptUserName = true;
  @Output() saveChatUserName = new EventEmitter();
  @Input() styleConfig: WidgetSettingsInterface;

  newName = '';
  darkTheme = ChatTheme.dark;
  // languages: string[] = ['en', 'pl'];

  constructor(
    private applicationStateService: ApplicationStateService,
    private colorModeService: ColorModeService,
    public settingsService: SettingsService,
    public translate: TranslateService,
    public applicationTranslateService: ApplicationTranslateService
  ) { }

  createNewUnregisteredUser(): void {
    this.saveChatUserName.emit(this.newName);
  }

  changeLanguage(lang: string) {
    this.applicationTranslateService.changeLanguage(lang);
  }
}
