import {Component, OnInit} from '@angular/core';
import {ApplicationStateService} from './services/application-state.service';
import {ApplicationState} from './common/enums/application-state';
import {ColorModeService} from './services/color-mode.service';
import {TranslateService} from '@ngx-translate/core';
import {WidgetType} from './common/interfaces/widget.interface';
import {ConfigStyleService} from './services/config-style.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    appState = ApplicationState;
    offsetHeight = 0;
    widgetTypeTemplate: WidgetType;

    constructor(
        public applicationStateService: ApplicationStateService,
        private colorModeService: ColorModeService, //fixme używany tylko do trybów kolorystycznych na wfirmie
        public translate: TranslateService,
        private _configStyleService: ConfigStyleService
    ) {
      translate.setDefaultLang('pl');
      translate.use('pl');
    }

  ngOnInit(): void {
      this._configStyleService.getConfig$()
          .pipe()
          .subscribe(configStyle => {
              this.widgetTypeTemplate = configStyle.widgetType;
          });

      const toolbarElement = document.querySelector('.toolbar') as HTMLElement;

      if (toolbarElement) {
        this.offsetHeight = toolbarElement.offsetHeight;
      }

      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('type') === WidgetType.Poll) {
          this.widgetTypeTemplate = WidgetType.Poll;
      }

      if (queryParams.get('type') === WidgetType.FullpageForm) {
          this.widgetTypeTemplate = WidgetType.FullpageForm;
      }
  }
    public changeCloseErrorMessage($event: boolean): void {
    this.appState = ApplicationState;
    this.applicationStateService.error = undefined;
    this.applicationStateService.setState(ApplicationState.READY);
  }

    protected readonly WidgetType = WidgetType;
}

