import {Component, Input} from '@angular/core';
import {ConfigStyleService} from "../../services/config-style.service";
import {WidgetSettingsInterface} from "../../common/interfaces/widget.interface";
import {ChatTheme} from "../../common/enums/chat-theme";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent {
    
    @Input() styleConfig: WidgetSettingsInterface;

    darkTheme = ChatTheme.dark;

    constructor (private _configStyleService: ConfigStyleService) {}

}
