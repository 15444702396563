import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {BrowserFingerprintService} from './browser-fingerprint.service';

@Injectable({
  providedIn: 'root'
})

export class PollWidgetService {
    formApiUrl = environment.apiUrl + '/widget_issues/add';
    constructor(
        private http: HttpClient,
        private browserFingerPrintService: BrowserFingerprintService) { }


    getPollWidgetSettings(widgetId: string): Observable<any> {
        const pollApiUrl = environment.apiUrl + `/chat_widgets/settings/${widgetId}`;
        const InterceptorSkipHeader = 'X-Skip-Interceptor';

        return this.http.get(pollApiUrl, {headers: new HttpHeaders().set(InterceptorSkipHeader, '')})
    }

    postFormData(answers): Observable<any> {
        const endpointSavePool = environment.apiUrl + `/poll_answers/save`;

        const httpOptions = {
            headers: new HttpHeaders({
                'X-Skip-Interceptor': '',
                'X-Five-Ways-Fingerprint': this.browserFingerPrintService.getFingerprint(),
                'X-Five-Ways-Custom-Fingerprint': this.browserFingerPrintService.getCustomFingerprint()
            })
        };

        return this.http.post(endpointSavePool, answers, httpOptions);
    }
}
