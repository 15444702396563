<div class="toolbar">
    <div class="toolbar__header"
    [ngStyle]="{'background-color': config.header.background}"
    *ngIf="!isLoading">
        <img *ngIf="employeeAvatar" [src]="employeeAvatar" alt="avatar" class="toolbar__avatar" crossorigin="anonymous">
        <span class="toolbar__title" [ngStyle]="{'color': config.header.labelColor, 'font-size': config.header.labelSize + 'px'}">
            {{config.header.chatLabel}}
        </span>
        <div class="toolbar__menu-icons">
            <mat-icon class="d-none" [ngStyle]="{ 'color': config.header.iconColor }">more_vert</mat-icon>
            <mat-icon [ngStyle]="{ 'color': config.header.iconColor }"
                      (click)="close()" [ngClass]="config.theme === darkMode ? 'toolbar__close-dark-mode' : 'toolbar__close-light-mode'">
                            expand_more
            </mat-icon>
        </div>
    </div>
</div>

