<div *ngIf="config" class="chat"
     [ngClass]="{
        'mobile-height': isMobile && isMobileHigh,
        'contrast-mode': isContrastTheme,
        'dark-mode': isDarkTheme }"
     [ngStyle]="{'background': config.actions.textAreaBackground}">
    <app-waiting
        *ngIf="applicationStateService.currentState === appState.LOADING"
        [styleConfig]="config"
        type="full"
        [ngStyle]="{'background': config.actions.textAreaBackground}"
    >
    </app-waiting>
    <!--  showWelcomeBox -->
    <app-welcome-box
        *ngIf="showWelcomeBox"
        [userName]="userName"
        [styleConfig]="config"
        [promptUserName]="true"
        (saveChatUserName)="saveChatUser($event)"
    >
    </app-welcome-box>

    <ng-container *ngIf="dataFetched">
        <app-chat-list
            *ngIf="!showWelcomeBox"
            [styleConfig]="config"
            [screenWidth]="screenWidth"
            [threadsWithMessages]="threadsWithMessages"
            [employeeTyping]="employeeTyping"
            (loadPrevious)="loadPreviousMessages()"
            (retryMessage)="retrySendMessage($event)">
        </app-chat-list>
        <app-chat-toolbar
            *ngIf="!showWelcomeBox"
            [currentThread]="lastOpenThread"
            [styleConfig]="config"
            [screenWidth]="screenWidth"
            [enabled]="applicationStateService.currentState === appState.READY"
            (newMessage)="sendMessage($event)"
            (closeThread)="closeThread($event)"
            (typing)="typing($event)">
        </app-chat-toolbar>
        <app-footer *ngIf="!showWelcomeBox"
             [styleConfig]="config">
        </app-footer>
    </ng-container>
</div>
