import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import {FormWidgetService } from '../services/form-widget.service';
import {TranslateService } from "@ngx-translate/core";
import {UserCustomTerm, WidgetSettingsInterface} from '../common/interfaces/widget.interface';
import {SettingsService} from "../services/settings.service";
import {ConfigStyleService} from "../services/config-style.service";
import {configMockup} from "../../assets/scripts/mockup";
import {Subscription, of} from "rxjs";
import {MainStoreService} from "../services/main-store.service";
import {UserStoreService} from "../services/user-store.service";
import {take, catchError} from 'rxjs/operators';
import {WfirmaMessageType} from "../common/enums/wfirma-message-type";
import {WfirmaCommunicationService} from "../services/wfirma-communication.service";
import {ChatTheme} from "../common/enums/chat-theme";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fullpageFormConfig: any;

  widgetFormGroup: FormGroup;

  successMsg_header = '';
  successMsc_subheader = '';
  errorBtnMsg = '';

  showForm = true;
  showSuccessMsg = false;
  showErrorMsg = false;
  dataSubmitted = false;
  formConfig: WidgetSettingsInterface;
  screenWidth: number;

  private configSubscription: Subscription;

  constructor(
        private mainStoreService: MainStoreService,
        private userStoreService: UserStoreService,
        private _formBuilder: FormBuilder,
        private formWidgetService: FormWidgetService,
        private _translate: TranslateService,
        private settingsService: SettingsService,
        private _configStyleService: ConfigStyleService,
        private _wfirmaCommunicationService: WfirmaCommunicationService,
        private _sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.widgetFormGroup = this._formBuilder.group({
            userMessage: ['', Validators.required],
            userName: ['', Validators.required],
            userLastname: [''],
            userEmail: ['', [Validators.required, Validators.email, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ]],
            userPhone: [''],
            termCheckboxAccept: [false],
            dataCheckboxAccept: [false]
        });

        this.configSubscription = this._configStyleService.getConfig$()
            .pipe(
                take(1),
                catchError(error => {
                    console.error('An error occurred, default values assigned:', error);
                    this.formConfig = configMockup as WidgetSettingsInterface;
                    return of(null);
                })
            )
            .subscribe(configStyle => {
                this.screenWidth = configStyle.screenWidth;
                this.formConfig = configStyle.settings;
            });
        this.updateLastnameValidation();
        this.updatePhoneValidation();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fullpageFormConfig && changes.fullpageFormConfig.currentValue) {
            this.updateLastnameValidation();
            this.updatePhoneValidation();
        }
    }

    ngOnDestroy() {
        if (this.configSubscription) {
            this.configSubscription.unsubscribe();
        }
    }

    get activeConfig(): WidgetSettingsInterface {
        return this.formConfig || this.fullpageFormConfig;
    }

    get isFullpageConfig(): boolean {
        return !!this.fullpageFormConfig;
    }
    transformQuillData(term: UserCustomTerm): SafeHtml {
        if (term && term.content) {
            return this._sanitizer.bypassSecurityTrustHtml(term.content);
        } else {
            return '';
        }
    }

    get formCtrlMsg() {
        return this.widgetFormGroup.get('userMessage');
    }

    get formCtrlName() {
        return this.widgetFormGroup.get('userName');
    }

    get formCtrlLastname() {
        return this.widgetFormGroup.get('userLastname');
    }

    get formCtrlEmail() {
        return this.widgetFormGroup.get('userEmail');
    }

    get formCtrlPhone() {
        return this.widgetFormGroup.get('userPhone');
    }

    get termCheckboxValue() {
        return this.widgetFormGroup.get('termCheckboxAccept');
    }

    get isMobile(): boolean {
        return this.screenWidth <= 450;
    }

    onFormDataSubmit() {

        if (this.widgetFormGroup.valid) {
            this.widgetFormGroup.markAllAsTouched();

            this.formWidgetService.postFormData(this.widgetFormGroup.value)
                .subscribe(
                    (response) => {
                        this.successMsg_header = this._translate.instant('VALIDATION.SUCCESS-HEADER-MSG');
                        this.successMsc_subheader = this._translate.instant('VALIDATION.SUCCESS-SUBHEADER-MSG');
                        this.showSuccessMsg = true;
                        this.showForm = false;
                    },
                    (error) => {
                        this.successMsg_header = this._translate.instant('VALIDATION.ERROR-HEADER-MSG');
                        this.successMsc_subheader = '';
                        this.errorBtnMsg = this._translate.instant('VALIDATION.TRY-AGAIN');
                        if ('TOO_MANY' === error.error.type) {
                            this.successMsg_header = this._translate.instant('VALIDATION.ERROR-HEADER-TOOMANY-MSG');
                            this.errorBtnMsg = this._translate.instant('VALIDATION.TRY-AGAIN-TOOMANY');
                        }
                        this.showErrorMsg = true;
                        this.showForm = false;
                    }
                )
        } else {
            this.dataSubmitted = true;
        }
    }

    showFormField() {
        this.showErrorMsg = false;
        this.showForm = true;
    }

    getClasses(): object {
        return {
            'fullpage-theme': this.isFullpageConfig,
            'dark-mode': !this.isFullpageConfig && this.activeConfig?.theme === ChatTheme.dark,
            'contrast-mode': !this.isFullpageConfig && this.activeConfig?.theme === ChatTheme.contrast
        };
    }

    updateLastnameValidation() {
        if ((this.formConfig && this.formConfig.actions.showLastnameInput) ||
            (this.fullpageFormConfig && this.fullpageFormConfig.actions.showLastnameInput)) {
            this.widgetFormGroup.get('userLastname').setValidators([
                Validators.required
            ]);
            this.widgetFormGroup.get('userLastname').updateValueAndValidity();
        }
    }

    updatePhoneValidation() {
        if ((this.formConfig && this.formConfig.actions.showPhoneInput) ||
            (this.fullpageFormConfig && this.fullpageFormConfig.actions.showPhoneInput)) {

            this.widgetFormGroup.get('userPhone').setValidators([
                Validators.required,
                Validators.pattern(/^\d+$/)
            ]);
            this.widgetFormGroup.get('userPhone').updateValueAndValidity();
        }
    }


    close(){
        this._wfirmaCommunicationService.sendMessageToParent(WfirmaMessageType.SEND_CLOSE);
    }
}
