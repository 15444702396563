import {Component, EventEmitter, Output} from '@angular/core';
import {ApplicationStateService} from '../services/application-state.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  @Output() onCloseErrorMessage = new EventEmitter<boolean>();

    constructor(public applicationStateService: ApplicationStateService,) {
    }

  public closeErrorMessage() : void {
    this.onCloseErrorMessage.emit(true);
  }
}
