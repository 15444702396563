import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserStoreService} from '../../services/user-store.service';
import {Message} from '../../common/classes/message';
import {MessageSendingStatus} from '../../common/enums/message-sending-status';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {activateLinks} from '../../common/utils/activate-links';
import {TranslateService} from '@ngx-translate/core';
import {WidgetSettingsInterface} from "../../common/interfaces/widget.interface";
import {ChatTheme} from "../../common/enums/chat-theme";

@Component({
    selector: 'app-chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {
    @Input() message: Message;
    @Input() threadOwnerId: number;
    @Input() isLast: boolean;
    @Input() styleConfig: WidgetSettingsInterface;

    @Output() retryMessage: EventEmitter<Message> = new EventEmitter();
    @Output() senderAvatar: EventEmitter<string> = new EventEmitter();

    sender;
    messageSendingStatus = MessageSendingStatus;
    messageShown: SafeHtml;
    contrastTheme = ChatTheme.contrast;
    darkTheme = ChatTheme.dark;

    public sendingStatus: {};

    constructor(
        public userStoreService: UserStoreService,
        private sanitizer: DomSanitizer,
        public translate: TranslateService) {
        this.sendingStatus = {
            [MessageSendingStatus.sending]: this.translate.instant('CAPTIONS.SENDING-STATUS_SENDING'),
            [MessageSendingStatus.sent]: this.translate.instant('CAPTIONS.SENDING-STATUS_SENT'),
            [MessageSendingStatus.received]: this.translate.instant('CAPTIONS.SENDING-STATUS_RECEIVED'),
            [MessageSendingStatus.error]: this.translate.instant('CAPTIONS.SENDING-STATUS_ERROR')
        };
    }
    ngOnInit() {
        const messageSenderId = this.message.reply ? (this.threadOwnerId || this.message.senderId) : this.message.senderId;

        messageSenderId
            ? this.userStoreService.getUser(messageSenderId).subscribe(sender => {
                this.sender = sender
                this.senderAvatar.emit(sender.avatar)})
            : this.sender = {name: ''};

        this.messageShown = this.sanitizer.bypassSecurityTrustHtml(activateLinks(this.message.message));
    }

    getStyleClasses() {
        const classes = [];

        this.message.reply ? classes.push('employee') : classes.push('client');

        classes.push(this.message.sendingStatus);

        if (this.isLast) {
            classes.push('last');
        }

        return classes;
    }
}
