import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UploaderOptions, UploadFile, UploadInput, UploadOutput} from 'ngx-uploader';
import {SettingsService} from '../../services/settings.service';
import {AuthService} from '../../services/auth/auth.service';
import {environment} from '../../../environments/environment';
import {from, of, Subject} from 'rxjs';
import {DataService} from '../../services/api/data.service';
import {concatMap, switchMap, take, tap} from 'rxjs/operators';
import {Message} from '../../common/classes/message';
import {MessageType} from '../../common/enums/message-type';
import {MessageSendingStatus} from '../../common/enums/message-sending-status';
import {MainStoreService} from '../../services/main-store.service';
import {ApplicationStateService} from '../../services/application-state.service';
import {AppError} from '../../common/classes/app-error';
import {ErrorType} from '../../common/enums/error-type';
import {ApplicationState} from '../../common/enums/application-state';
import {SocketsService} from '../../services/sockets.service';
import {WidgetSettingsInterface} from 'src/app/common/interfaces/widget.interface';
import {TranslateService} from '@ngx-translate/core';
import {ChatTheme} from "../../common/enums/chat-theme";

@Component({
    selector: 'app-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
    @Input() threadId: number;
    @Input() enabled: boolean;
    @Input() styleConfig: WidgetSettingsInterface;

    @Output() refresh = new EventEmitter();

    @ViewChild('fileInput') fileInput: ElementRef;

    uploadComplete$ = new Subject<void>();
    options: UploaderOptions;
    files: UploadFile[];
    uploadInput: EventEmitter<UploadInput>;
    darkMode = ChatTheme.dark;

    constructor(
        private settingsService: SettingsService,
        private authService: AuthService,
        private dataService: DataService,
        private mainStoreService: MainStoreService,
        private applicationStateService: ApplicationStateService,
        private socketsService: SocketsService,
        public translate: TranslateService
    ) {
        this.options = this.settingsService.fileUploaderOptions;
        this.files = []; // local uploading files array
        this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    }

    private submitFile(messageId: number, file: UploadFile) {
        const headers = this.authService.headers;

        delete headers['Content-Type'];

        const event: UploadInput = {
            type: 'uploadFile',
            url: environment.restApiUrl + 'common_file_chat_message/',
            method: 'POST',
            file,
            headers,
            data: {chat_message_id: messageId.toString()}
        };

        this.uploadInput.emit(event);
    }

    private createNewMessage(threadId: number) {
        return new Message(-1, threadId, MessageType.file, ' ', null, null, MessageSendingStatus.sending);
    }

    private getAddDataObservable() {
        if (this.threadId) {
            return this.dataService.postMessage(this.createNewMessage(this.threadId));
        } else {
            const closeThread$ = this.mainStoreService.lastOpenThreadInStore
                ? this.dataService.closeThread(this.mainStoreService.lastOpenThreadInStore.id)
                : of(null);

            return closeThread$.pipe(
                switchMap(() => this.dataService.postThread(this.mainStoreService.createNewThread())
                    .pipe(
                        tap(thread => this.socketsService.joinMessageChannel(+thread.id)),
                        switchMap(thread => this.dataService.postMessage(this.createNewMessage(+thread.id)))
                    )
                )
            );
        }
    }

    private uploadStart() {
        from(this.files)
            .pipe(
                tap(() => this.applicationStateService.setState(ApplicationState.LOADING)),
                take(this.files.length),
                concatMap(file => this.getAddDataObservable().pipe(
                        tap(message => this.submitFile(+message.id, file))
                    )
                )
            )
            .subscribe(
                () => {
                    this.applicationStateService.setState(ApplicationState.READY);
                    this.files = [];
                    this.refresh.emit();
                },
                err => this.applicationStateService.setInfoError(new AppError(ErrorType.fileUpload, this.translate.instant('FILE-UPLOADER.ADD-ATTACHMENT_ERROR'), err))
            );
    }

    onUploadOutput(output: UploadOutput): void {
        switch (output.type) {
            case 'allAddedToQueue':
                this.uploadStart();

                break;
            case 'addedToQueue':
                if (typeof output.file !== 'undefined') {
                    const fileNameArr = output.file.name.split('.'),
                        fileExtension = fileNameArr[fileNameArr.length - 1].toLowerCase();

                    if (!(this.settingsService.allowedFileContentTypes.includes(fileExtension))) {
                        this.applicationStateService.setInfoError(new AppError(ErrorType.fileUpload, this.translate.instant('FILE-UPLOADER.ADD-ATTACHMENT_WRONG-FILE-ERROR'), null));
                        this.refresh.emit();

                        return;
                    }

                    this.files.push(output.file);
                }

                break;
            case 'uploading':
                if (typeof output.file !== 'undefined') {
                    // update current data in files array for uploading file
                    const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);

                    this.files[index] = output.file;
                }
                break;
            case 'removed':
                // remove file from array when removed
                this.files = this.files.filter((file: UploadFile) => file !== output.file);

                break;
            case 'rejected':
                this.applicationStateService.setInfoError(new AppError(ErrorType.fileUpload, this.translate.instant('FILE-UPLOADER.ADD-ATTACHMENT_FILE-TOO-LARGE'), null));
                this.refresh.emit();

                break;
            case 'done':
                // The file is downloaded
                this.uploadComplete$.next();
                break;
        }
    }

    openSaveFileDialog() {
        this.files = [];
        this.fileInput.nativeElement.click();
    }
}
