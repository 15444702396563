import {Injectable} from '@angular/core';
import {WfirmaCommunicationService} from './wfirma-communication.service';
import {SettingsService} from './settings.service';
import {ApplicationStateService} from './application-state.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ColorModeService {

    colorMode$ = new BehaviorSubject('normal');

    constructor(
        private wfirmaCommunicationService: WfirmaCommunicationService,
        private settingsService: SettingsService,
        public applicationStateService: ApplicationStateService
    ) {
        this.setColorModeSubscription();
    }

    private setColorModeSubscription() {
        this.wfirmaCommunicationService.colorMode$.asObservable().subscribe(
            (colorModeName: string) => {
                const properties = this.settingsService.getColorModeProperties(colorModeName),
                    root = document.documentElement;

                if (!properties || !Array.isArray(properties)) {
                    return;
                }

                for (const prop of properties) {
                    root.style.setProperty(prop.key, prop.value);
                }

                this.colorMode$.next(colorModeName);
            }
        );
    }
}
