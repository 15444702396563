import {Injectable} from '@angular/core';
import {ApplicationStateService} from './application-state.service';
import {BehaviorSubject} from 'rxjs';
import {ClientJS} from 'clientjs';

@Injectable({
    providedIn: 'root'
})
export class BrowserFingerprintService {

    fp1;
    fp2;

    clientJS;

    customFingerprintElements = [];

    constructor(
        public applicationStateService: ApplicationStateService
    ) {
        this.clientJS = new ClientJS();
        this.initFingerprint();
    }

    private initFingerprint() {
        this.loadFingerprintsFromStore();
        this.fetchIP();
    }

    private fetchIP() {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(ipResponse => {
                this.customFingerprintElements.push(ipResponse.ip);
            })
            .catch(error => console.error('Error:', error));
    }

    private generateFingerprint() {
        return this.clientJS.getFingerprint();
    }

    private generateCustomFingerprint() {
        this.prepareCustomFingerprintElements();
        return this.clientJS.getCustomFingerprint(...this.customFingerprintElements);
    }

    private loadFingerprintsFromStore() {
        let fp1Check = localStorage.getItem(this.getFpN());
        let fp2Check = localStorage.getItem(this.getFpN(true));

        if (null === fp1Check || 'undefined' === typeof fp1Check || 0 >= fp1Check.length) {
            fp1Check = this.readFingerprintFromCookie();
        }

        if (null === fp1Check || 'undefined' === typeof fp1Check || 0 >= fp1Check.length) {
            fp1Check = this.generateFingerprint();
        }

        if (null === fp2Check || 'undefined' === typeof fp2Check || 0 >= fp2Check.length) {
            fp2Check = this.readFingerprintFromCookie();
        }

        if (null === fp2Check || 'undefined' === typeof fp2Check || 0 >= fp2Check.length) {
            fp2Check = this.generateCustomFingerprint();
        }

        this.fp1 = fp1Check;
        this.fp2 = fp2Check;

        this.saveFingerprintsToStore();
    }

    private saveFingerprintsToStore() {
        this.saveFingerprintToStore();
        this.saveFingerprintToStore(true);
    }

    private saveFingerprintToStore(useExtended = false) {
        const date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        const expire = date.toUTCString();
        document.cookie = this.getFpN(useExtended) + '=' + this.getFpVal(useExtended) + '; expires=' + expire + '; path=/';
        localStorage.setItem(this.getFpN(useExtended), this.getFpVal(useExtended));
    }

    private readFingerprintFromCookie(useExtended = false) {
        const cookieArr = document.cookie.split(';');

        for (let i = 0; i < cookieArr.length; i++) {
            const cookiePair = cookieArr[i].split('=');

            if (this.getFpN(useExtended) === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1]);
            }
        }

        return null;
    }

    public getFpN(useExtended = false) {
        return useExtended ? 'fp2' : 'fp1';
    }

    public getFpVal(useExtended = false) {
        return useExtended ? this.fp2 : this.fp1;
    }

    private prepareCustomFingerprintElements() {
        const clientJSFingerprintCandidates = [
            this.clientJS.getOS(),
            this.clientJS.getOSVersion(),
            this.clientJS.getColorDepth(),
            this.clientJS.getCurrentResolution(),
            this.clientJS.getAvailableResolution(),
            this.clientJS.getFonts(),
            this.clientJS.getTimeZone(),
            this.clientJS.getLanguage(),
            this.clientJS.getSystemLanguage(),
            this.clientJS.getCanvasPrint()
        ];

        for (let i = 0; i < clientJSFingerprintCandidates.length; i++) {
            if ('undefined' !== typeof clientJSFingerprintCandidates[i] && 0 < clientJSFingerprintCandidates[i].length) {
                this.customFingerprintElements.push(clientJSFingerprintCandidates[i]);
            }
        }
    }

    getFingerprint() {
        return this.fp1;
    }

    getCustomFingerprint() {
        return this.fp2;
    }
}
