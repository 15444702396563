<div class="chat-message" [ngClass]="getStyleClasses()">
    <ng-container *ngIf="message.reply; else questionTemplate">

        <img *ngIf="sender?.avatar; else defaultAvatarTemplate" [src]="sender.avatar" alt="avatar" class="chat-message__avatar" crossorigin="anonymous">

        <div class="chat-message__text"
             [ngClass]="styleConfig.theme === contrastTheme ? 'chat-message__text-border' : ''"
             [ngStyle]="{'background-color': styleConfig.content.supportBackground,
             'color': styleConfig.content.supportColor,
             'font-size': styleConfig.content.fontSize + 'px',
             'line-height': '20px'}">
            <span [innerHTML]="messageShown"></span>
        </div>
    </ng-container>
</div>

<ng-template #defaultAvatarTemplate>
    <img src="/assets/images/default-avatar.svg" alt="avatar" class="chat-message__avatar">
</ng-template>

<ng-template #questionTemplate>
    <div class="chat-message__text"
         [ngClass]="styleConfig.theme === contrastTheme ? 'chat-message__text-border' : ''"
         [ngStyle]="{'background-color': styleConfig.content.clientBackground,
         'color': styleConfig.content.clientColor,
         'font-size': styleConfig.content.fontSize + 'px',
         'line-height': '20px'}">
        <span [innerHTML]="messageShown"></span>
    </div>
    <div
        *ngIf="message.sendingStatus === messageSendingStatus.error; else sendigStatusTemplate"
        class="sending-status error"
        (click)="retryMessage.emit(message)"
        matTooltip="{{ 'CHAT-MESSAGE.SEND-MESSAGE' | translate }}"
    >
        {{sendingStatus[message.sendingStatus]}}
    </div>
</ng-template>

<ng-template #sendigStatusTemplate>
    <div class="sending-status" [ngClass]="styleConfig.theme === darkTheme ? 'dark-mode' : ''">{{sendingStatus[message.sendingStatus]}}</div>
</ng-template>


