import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Thread} from '../../common/classes/thread';
import {TranslateService} from '@ngx-translate/core';
import {WidgetSettingsInterface} from 'src/app/common/interfaces/widget.interface';
import {ChatTheme} from "../../common/enums/chat-theme";

@Component({
    selector: 'app-chat-toolbar',
    templateUrl: './chat-toolbar.component.html',
    styleUrls: ['./chat-toolbar.component.scss']
})
export class ChatToolbarComponent {
    @Input() currentThread: Thread;
    @Input() enabled: boolean;
    @Input() styleConfig: WidgetSettingsInterface;
    @Input() screenWidth: number;

    @Output() newMessage: EventEmitter<string> = new EventEmitter();
    @Output() closeThread: EventEmitter<number> = new EventEmitter();
    @Output() typing: EventEmitter<number> = new EventEmitter();

    text = '';
    expand = false;
    fileUploaderVisible = true;
    contrastTheme = ChatTheme.contrast;
    darkTheme = ChatTheme.dark;
    customMaxRows = 4;

    constructor(
        public translate: TranslateService,
    ) {
    }

    sendMessage() {
        if (!this.enabled || !this.text) {
            return;
        }

        this.newMessage.emit(this.text);
        this.text = '';
        this.expand = false;
    }

    sendIsTyping() {
        if (this.currentThread) {
            this.typing.emit(+this.currentThread.id);
        }
    }

    closeCurrentThread() {
        this.closeThread.emit(this.currentThread.id);
    }

    toggleExpand() {
        this.expand = !this.expand;
        if (this.expand) {
            this.customMaxRows = 8;
        } else {
            this.customMaxRows = 4;
        }
    }

    onInputKeyDown() {
        this.sendIsTyping();
    }

    onUploadRefresh() {
        this.fileUploaderVisible = false;
        setTimeout(() => this.fileUploaderVisible = true, 100);
    }

    get isMobile(): boolean {
        return this.screenWidth <= 450;
    }

    get isContrast() {
        return this.styleConfig.theme === ChatTheme.contrast;
    }

    get isDarkMode(): boolean {
        return this.styleConfig.theme === ChatTheme.dark;
    }

    getTextareaClasses() {
        return {
            'chat-toolbar__textarea-contrast': this.isContrast,
            'chat-toolbar__textarea-darkmode': this.isDarkMode,
            'chat-toolbar__textarea-default': !this.isContrast,
            'chat-toolbar__textarea-desktop': !this.isMobile,
            'chat-toolbar__textarea-expanded': this.expand && !this.isMobile,
            'chat-toolbar__textarea-default-size': !this.expand && !this.isMobile,
            'chat-toolbar__textarea-mobile-expanded': this.expand && this.isMobile,
            'chat-toolbar__textarea-mobile-default-size': !this.expand && this.isMobile
        }
    }
}
