import {Component, OnDestroy, OnInit} from '@angular/core';
import {catchError, distinctUntilChanged, filter, skip, switchMap, take, tap} from 'rxjs/operators';
import {combineLatest, merge, of, Subscription, timer} from 'rxjs';
import {MainStoreService} from '../../services/main-store.service';
import {AuthService} from '../../services/auth/auth.service';
import {UserStoreService} from '../../services/user-store.service';
import {SocketsService} from '../../services/sockets.service';
import {ApplicationStateService} from '../../services/application-state.service';
import {ApplicationState} from '../../common/enums/application-state';
import {ErrorType} from '../../common/enums/error-type';
import {ThreadStatus} from '../../common/enums/thread-status';
import {SettingsService} from '../../services/settings.service';
import {Message} from '../../common/classes/message';
import {UsersService} from '../../services/api/users.service';
import {WidgetSettingsInterface, WidgetType} from 'src/app/common/interfaces/widget.interface';
import {ConfigStyleService} from 'src/app/services/config-style.service';
import {configMockup} from '../../../assets/scripts/mockup';
import {ChatTheme} from "../../common/enums/chat-theme";

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {

    threadsWithMessages = [];
    appState = ApplicationState;
    subscriptions = new Subscription();
    employeeTyping = false;
    isReplyingTimeout: number;
    userName: string;
    userId = '';
    showWelcomeBox = false;
    dataFetched = false;
    promptUserName: boolean;
    config: WidgetSettingsInterface;
    screenWidth: number;
    screenHeight: number;

    private configSubscription: Subscription;

    constructor(
        private mainStoreService: MainStoreService,
        private authService: AuthService,
        public applicationStateService: ApplicationStateService,
        private userStoreService: UserStoreService,
        private socketsService: SocketsService,
        private settingsService: SettingsService,
        private _userService: UsersService,
        private _configStyleService: ConfigStyleService
    ) {

    }

    private loadAllData() {
        this.applicationStateService.setState(ApplicationState.LOADING);
        this.showWelcomeBox = false;
        this.userStoreService.loadClientData(this.authService.getUserId());
        this.mainStoreService.loadMessagesFirstTime();
        this.userStoreService.getChatUserData();
        this.socketsService.connect();
    }

    private clearAllData() {
        this.socketsService.disconnect();
        this.userStoreService.resetStore();
        this.mainStoreService.resetStore();
        this.userName = '';
        this.userId = '';
    }

    private setLoginSubscription() {
        this.subscriptions.add(
            this.authService.isLoggedIn$
                .pipe(
                    distinctUntilChanged(),
                    filter(isLoggedIn => isLoggedIn)
                )
                .subscribe(() => {
                    this.loadAllData()
                })
        );

        this.subscriptions.add(
            this.authService.isLoggedIn$
                .pipe(filter(isLoggedIn => !isLoggedIn))
                .subscribe(() => this.clearAllData())
        );
    }

    private setSocketTypingMessageSubscription() {
        this.subscriptions.add(
            this.socketsService.typingMessage$
                .pipe(
                    tap(() => {
                        if (!this.employeeTyping) {
                            this.employeeTyping = true;
                        }
                    }),
                    switchMap(() => merge(timer(this.isReplyingTimeout), this.mainStoreService.data$.pipe(take(1)))),
                )
                .subscribe(() => {
                    if (this.employeeTyping) {
                        this.employeeTyping = false;
                    }
                })
        );
    }

    private setDataSubscription() {
        this.subscriptions.add(
            combineLatest([
                this.mainStoreService.data$.pipe(skip(1)),
                this.userStoreService.chatUser$
            ]).subscribe(
                ([threadsWithMessages, chatUser]) => {
                    this.threadsWithMessages = threadsWithMessages;
                    this.userName = chatUser.userName;
                    this.userId = chatUser.userId;
                    this.showWelcomeBox = !chatUser.userName;
                    this.promptUserName = this.userName === '' || this.userName.toLowerCase() === 'admin';
                    this.dataFetched = true;
                }
            )
        );
    }

    ngOnInit() {
        this.setLoginSubscription();
        this.setSocketTypingMessageSubscription();
        this.setDataSubscription();

        if (!this.authService.tryLoginByToken()) {
            this.showWelcomeBox = true;
        }

        this.isReplyingTimeout = this.settingsService.isReplyingTimeout;
        this.configSubscription = this._configStyleService.getConfig$()
            .pipe(
                skip(1),
                catchError(error => {
                    console.error('An error occurred, default values assigned:', error);
                    this.config = configMockup as WidgetSettingsInterface;
                    return of(null);
                })
            )
            .subscribe(configStyle => {
                this.config = configStyle.settings;
                this.screenWidth = configStyle.screenWidth;
                this.screenHeight = configStyle.screenHeight;

                this.setLoginSubscription();
                this.setSocketTypingMessageSubscription();
                this.setDataSubscription();

                if (!this.authService.tryLoginByToken()) {
                    this.showWelcomeBox = true;
                }
            })
    }

    ngOnDestroy() {
        if (this.configSubscription) {
            this.configSubscription.unsubscribe();
        }
    }

    get lastOpenThread() {
        if (!this.mainStoreService.lastThreadInStore) {
            return null;
        }

        return this.mainStoreService.lastThreadInStore.status === ThreadStatus.closed
            ? null
            : this.mainStoreService.lastThreadInStore;
    }

    sendMessage(text: string) {
        this.mainStoreService.addQuestion(text);
    }

    closeThread(threadId: number) {
        this.mainStoreService.closeThread(threadId);
    }

    loadPreviousMessages() {
        this.mainStoreService.loadPreviousMessages();
    }

    retry() {
        switch (this.applicationStateService.error.errorType) {
            case ErrorType.apiLogin:
                this.authService.retryLogin();
                break;

            case ErrorType.apiGet:
                this.loadAllData();
                break;
        }
    }

    typing(threadId: number) {
        this.socketsService.typing(threadId);
    }

    saveChatUser(name: string) {
        this._userService.createNewChatUser(name).subscribe(data => {
            this.authService.login({login: data.data.login, password: data.data.password});
        });
    }

    retrySendMessage(message: Message) {
        this.mainStoreService.retrySendMessage(message);
    }

    get isContrastTheme(): boolean {
        return this.config.theme === ChatTheme.contrast;
    }

    get isDarkTheme(): boolean {
        return this.config.theme === ChatTheme.dark;
    }

    get isMobile() {
        return this.screenWidth <= 450;
    }

    get isMobileHigh() {
        return this.screenHeight >= 750;
    }
}
