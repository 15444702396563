export const InterceptorSkipHeader = 'X-Skip-Interceptor';

export function compareObjectsById(a, b) {
    if (a.id === -1 || b.id === -1) {
        return 0;
    }

    return a.id - b.id;
}

export function dateFromApiString(date: string) {
    return new Date(date);
}
