import {Component, OnInit} from '@angular/core';
import {PollWidgetService} from "../services/poll-widget.service";
import {Title} from '@angular/platform-browser';
import {PollStyles} from "../common/interfaces/poll-settings.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApplicationState} from "../common/enums/application-state";
import {ApplicationStateService} from "../services/application-state.service";
import { Meta } from '@angular/platform-browser';

@Component({
    selector: 'app-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss']
})

export class PollComponent implements OnInit {

    type: string;
    widgetId: string;
    settings;
    questions;
    pollSubmitted: boolean = false;
    pollStyles: PollStyles = {
        titleFontSize: 26,
        questionFontSize: 18,
        answerFontSize: 16,
        backgroundColor: '#f5f5f5'
    };
    hasRequiredQuestion: boolean;
    isPollActive: string;
    appState = ApplicationState;

    constructor(private pollWidgetService: PollWidgetService,
                private snackBar: MatSnackBar,
                protected applicationStateService: ApplicationStateService,
                private titleService: Title, private meta: Meta) {
    }

    ngOnInit() {
        const queryParams = new URLSearchParams(window.location.search);
        this.type = queryParams.get('type');
        this.widgetId = queryParams.get('uuid');
        this.getPollSettings(this.widgetId);
    }

    getPollSettings(widgetId) {
        this.pollWidgetService.getPollWidgetSettings(widgetId).subscribe(
            widgetSettings => {
                if (!widgetSettings.settings) {
                    this.applicationStateService.setState(this.appState.ERROR);
                } else {
                    const parsedSettings = JSON.parse(widgetSettings.settings);

                    this.questions = parsedSettings.settings.questions;
                    this.pollStyles = parsedSettings.settings.styles;
                    this.titleService.setTitle(parsedSettings.name);
		    this.meta.updateTag({property: 'og:title', content: parsedSettings.name});
                    this.hasRequiredQuestion = this.questions.some(question => question.data.required);
                    this.isPollActive = widgetSettings.is_active;
                }
            }
        );
    }

    hasContactSection() {
        return this.questions ? this.questions.some(question => question.type === 'contact') : false;
    }

    getContactSection() {
        return this.questions.find(question => question.type === 'contact');
    }

    getHeader() {
        return this.questions.find(question => question.type === 'header');
    }

    range(from: number, to: number): number[] {
        return Array.from({length: to - from}, (_, i) => i + from);
    }

    onPollDataSubmit() {
        const cleanedQuestions = this.questions.map(question => {
            if (question.type === "header") {
                delete question.data.description;
            } else {
                delete question.uuid
                if (question.data.options) {
                    question.data.options.forEach(option => {
                        delete option.uuid;
                    });
                }
            }
            return question;
        });

        this.pollWidgetService.postFormData(cleanedQuestions)
            .subscribe(
                (response) => {
                    this.pollSubmitted = true;
                    this.snackBar.open('Ankieta została wysłana.', '', {panelClass: ['success-snackbar']});
                },
                (error) => {
                    if (error.error.message || error.error.message !== '') {
                        this.snackBar.open(error.error.message + '.', '', {panelClass: ['error-snackbar']});
                    } else {
                        this.snackBar.open('Błąd wysyłki. Wypełnij wymagane pola.', '', {panelClass: ['error-snackbar']});
                    }
                }
            )
    }

    updateGridQuestionValue(rowIndex: number, colIndex: number, checked: boolean, uuid) {
        const questionIndex = this.questions.findIndex(question => question.uuid === uuid);

        if (checked) {
            this.questions[questionIndex].data.rows[rowIndex].selected.push(colIndex);
        } else {
            const indexToRemove = this.questions[questionIndex].data.rows[rowIndex].selected.indexOf(colIndex);
            if (indexToRemove !== -1) {
                this.questions[questionIndex].data.rows[rowIndex].selected.splice(indexToRemove, 1);
            }
        }
    }

    updateGridOneQuestionValue(rowIndex: number, colIndex: number, checked: boolean, uuid) {
        const questionIndex = this.questions.findIndex(question => question.uuid === uuid);

        this.questions[questionIndex].data.rows[rowIndex].selected = colIndex;
    }

    anyRequiredFieldEmptyFlag: boolean = false;

    anyRequiredFieldEmpty() {
        if (!this.questions || this.questions.length === 0) {
            this.anyRequiredFieldEmptyFlag = false;
            return false;
        }

        for (let question of this.questions) {
            if (this.isQuestionEmpty(question)) {
                return true;
            }
        }

        this.anyRequiredFieldEmptyFlag = false;
        return false;
    }

    isQuestionEmpty(question) {
        if (!question.data.required) {
            return false;
        }

        switch (question.type) {
            case "shortQuestion":
            case "longQuestion":
                return !question.data.value;

            case "lineScaleQuestion":
                return (question.data.value === null || question.data.value === undefined || question.data.value === "");

            case "oneOptionQuestion":
            case "multipleOptionQuestion":
            case "selectQuestion":
                return !question.data.options.some(option => option.selected);

            case "gridOneQuestion":
                return !question.data.rows.some(col => col.selected === 0 || col.selected > 0);

            case "gridQuestion":
                return !question.data.rows.some(col => col.selected.length > 0);

            default:
                return false;
        }
    }

    clearOtherSelectedOptions(options: any[], selectedOption: any) {
        options.forEach(option => {
            if (option !== selectedOption) {
                option.selected = false;
            }
        });
    }

    updateSelectedOption(selectedUuid: string) {
        for (let question of this.questions) {
            if (question.type === "selectQuestion") {

                const selectedIndex = question.data.options.findIndex(option => option.uuid === selectedUuid);

                if (selectedIndex !== -1) {
                    question.data.options.forEach((option, index) => {
                        if (index === selectedIndex) {
                            option.selected = true;
                        } else {
                            option.selected = false;
                        }
                    });
                }
            }
        }
    }

    updateOneOption(selectedUuid: string) {
        for (let question of this.questions) {
            if (question.type === "oneOptionQuestion") {
                question.data.options.forEach(option => {
                    if (question.data.options.some(opt => opt.uuid === selectedUuid)) {
                        option.selected = (option.uuid === selectedUuid);
                    }
                });
            }
        }
    }

}
