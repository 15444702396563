<ng-container [ngSwitch]="widgetTypeTemplate">
    <ng-container *ngSwitchCase="WidgetType.Chat" [ngTemplateOutlet]="chatTemplate"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.Form" [ngTemplateOutlet]="formTemplate"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.Poll" [ngTemplateOutlet]="pollTemplate"></ng-container>
    <ng-container *ngSwitchCase="WidgetType.FullpageForm" [ngTemplateOutlet]="fullpageFormTemplate"></ng-container>
</ng-container>

<ng-template class="main-container" #chatTemplate>
        <app-toolbar class="toolbar-block"></app-toolbar>
        <app-chat [ngStyle]="{'top': offsetHeight + 'px'}"
                  class="chat">
        </app-chat>
        <app-error
            (onCloseErrorMessage)="changeCloseErrorMessage($event)"
            *ngIf="applicationStateService.currentState === appState.ERROR">
        </app-error>
</ng-template>

<ng-template class="form-container" #formTemplate>
        <app-form></app-form>
</ng-template>

<ng-template #pollTemplate>
    <app-poll></app-poll>
</ng-template>

<ng-template #fullpageFormTemplate>
    <app-fullpage-form></app-fullpage-form>
</ng-template>
