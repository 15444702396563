export enum WfirmaMessageType {
    'SEND_READY' = 'chat-ready',
    'SEND_CLOSE' = 'chat-close',
    'SEND_ERROR' = 'chat-error',
    'SEND_GET_COMPANY_DATA' = 'chat-client-info',
    'LOGIN' = 'wfirma-login',
    'LOGOUT' = 'wfirma-logout',
    'COLOR_MODE' = 'wfirma-color-mode',
    'COMPANY_DATA' = 'wfirma-client-info',
    'CREATE_NEW_CHAT_CLIENT' = 'wfirma-create-new-client',
    'WIDGET_SETTINGS' = 'chat-widget-settings',
    'WIDGET_TYPE' = 'widget-type',
}
