import {Injectable, signal} from '@angular/core';
import {ApplicationState} from '../common/enums/application-state';
import {AppError} from '../common/classes/app-error';
import {Subject, timer} from 'rxjs';
import {SettingsService} from './settings.service';
import {environment} from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApplicationStateService {
    currentState: ApplicationState = ApplicationState.READY;
    error: AppError;
    permanent = false;
    infoErrorTimeout: number;
    infoErrorTimeout$ = new Subject<void>();

    public customerData = signal({
        customer_id: environment.customer_id,
        client_id: environment.apiClientId,
        external_hash: ''
    })

    constructor(private settingsService: SettingsService) {
      this.infoErrorTimeout = this.settingsService.infoErrorTimeout;
    }

    setState(state: ApplicationState) {
        this.currentState = state;
        this.error = null;
        this.permanent = false;
    }

    setError(error: AppError) {
        this.currentState = ApplicationState.ERROR;
        this.error = error;
        this.permanent = true;
    }

    setInfoError(error: AppError) {
        this.currentState = ApplicationState.ERROR;
        this.error = error;
        this.permanent = false;

        timer(this.infoErrorTimeout).subscribe(() => {
            this.setState(ApplicationState.READY);
            this.infoErrorTimeout$.next();
        });
    }
}
