import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {map, retryWhen, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {Message} from '../../common/classes/message';
import {Thread} from '../../common/classes/thread';
import {ApiChatMessage} from '../../common/interfaces/api-chat-message';
import {DataResponseInterface} from '../../common/interfaces/data-response-interface';
import {ApiChatThread} from '../../common/interfaces/api-chat-thread';
import {ThreadStatus} from '../../common/enums/thread-status';
import {ThreadSendingStatus} from '../../common/enums/thread-sending-status';
import {dateFromApiString} from '../../common/utils/utils';
import {MessageSendingStatus} from '../../common/enums/message-sending-status';
import {MessageType} from '../../common/enums/message-type';
import {AuthService} from '../auth/auth.service';
import {genericRetryStrategy} from '../../common/utils/retry-strategy';
import {WfirmaCommunicationService} from '../wfirma-communication.service';
import {ApplicationStateService} from "../application-state.service";

@Injectable({
    providedIn: 'root'
})
export class DataService {
    messagesUrl = environment.restApiUrl + 'chat_message/';
    threadsUrl = environment.restApiUrl + 'chat_thread/';

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private wfirmaCommunicationService: WfirmaCommunicationService,
        private applicationStateService: ApplicationStateService
    ) {
    }

    private mapChatIssueSentenceToMessage(chatIssueSentence: ApiChatMessage) {
        return new Message(
            +chatIssueSentence.id,
            +chatIssueSentence.chat_thread_id,
            MessageType[chatIssueSentence.type],
            chatIssueSentence.message,
            +chatIssueSentence.is_reply === 1,
            +chatIssueSentence.user_id,
            +chatIssueSentence.is_read === 1 ? MessageSendingStatus.received : MessageSendingStatus.sent
        );
    }

    private mapChatIssueToThread(chatIssue: ApiChatThread) {
        return new Thread(
            +chatIssue.id,
            dateFromApiString(chatIssue.created),
            ThreadStatus[chatIssue.status],
            ThreadSendingStatus.sent,
            +chatIssue.owner_id
        );
    }

    getFirstMessageId() {
        return this.http.get<DataResponseInterface>(this.messagesUrl + '?limit=1&order=id|asc').pipe(
            retryWhen(genericRetryStrategy()),
            map(result => result.results[0]),
            map((message: any) => message ? +message.ChatMessage.id : null),
        );
    }

    getMessages(lastMessageId: number, order: string, packetSize: number) {
        const idQuery = lastMessageId
            ? order === 'desc'
                ? '&id=lt_' + lastMessageId
                : '&id=gt_' + lastMessageId
            : '',
            query = '?external_id=' + this.wfirmaCommunicationService.companyId + '&order=id|' + order + '&limit=' + packetSize + idQuery;

        return this.http.get<DataResponseInterface>(this.messagesUrl + query).pipe(
            map(result => result.results),
            map(messages => messages.map(message => this.mapChatIssueSentenceToMessage(message.ChatMessage)))
        );
    }

    getThreads(threadIds: number[]) {
        if (!threadIds.length) {
            return EMPTY;
        }

        return this.http.get<DataResponseInterface>(this.threadsUrl + '?id=' + threadIds.join(',')).pipe(
            retryWhen(genericRetryStrategy()),
            map(result => result.results),
            map((chatThreads: any[]) => chatThreads.map(thread => this.mapChatIssueToThread(thread.ChatThread)))
        );
    }

    // TODO Jak serwer będzie gotowy trzeba usunąć  customer_id: 86
    postMessage(message: Message): Observable<ApiChatMessage> {
        const body = {
            ChatMessage: {
                user_id: this.authService.getUserId(),
                chat_thread_id: message.threadId,
                message: message.message,
                reply: 0,
                type: message.type,
                read: 0,
                external_id: this.wfirmaCommunicationService.companyId,
                customer_id: this.applicationStateService.customerData().customer_id,
            }
        };

        return this.http.post<ApiChatMessage>(this.messagesUrl, JSON.stringify(body)).pipe(
            retryWhen(genericRetryStrategy()),
        );
    }

    // TODO Jak serwer będzie gotowy trzeba usunąć  customer_id: 86
    postThread(thread: Thread): Observable<ApiChatThread> {
        const body = {
            ChatThread: {
                status: thread.status,
                client_info: thread.clientInfo,
                external_id: this.wfirmaCommunicationService.companyId,
                customer_id: this.applicationStateService.customerData().customer_id
            }
        };

        return this.http.post<ApiChatThread>(this.threadsUrl, JSON.stringify(body)).pipe(
            retryWhen(genericRetryStrategy()),
        );
    }

    closeThread(threadId: number) {
        const body = {
            ChatThread: {
                status: ThreadStatus.closed,
            }
        };

        return this.http.put(this.threadsUrl + threadId, body).pipe(
            retryWhen(genericRetryStrategy())
        );
    }

    getMessageAttachment(messageId: number): Observable<any> {
        return this.http.get<DataResponseInterface>(environment.restApiUrl + 'common_file/common_file_object?CommonFileObject_object_name=ChatMessage&CommonFileObject_object_id=' + messageId)
            .pipe(
                tap(result => {
                    if (!result.results.length) {
                        throw new Error('Brak danych');
                    }
                }),
                retryWhen(genericRetryStrategy()),
                map(result => result.results)
            );
    }

    downloadAttachment(commonFileId): Observable<any> {
        return this.http.get(environment.restApiUrl + 'common_file/' + commonFileId, {responseType: 'blob', observe: 'response'});
    }
}
