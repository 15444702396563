<div class="container">
    <div class="row">
        <div class="error-image">
            <img src="/assets/images/404-pl.webp" class="w-100" alt="404">
        </div>
        <div class="error-text">
            Ankieta jest niedostępna
        </div>
    </div>
</div>
