import {Injectable} from '@angular/core';
import {EMPTY, merge, Subject, timer} from 'rxjs';
import {mapTo, switchMap, take, tap} from 'rxjs/operators';
import {Message} from '../common/classes/message';
import {Thread} from '../common/classes/thread';
import {compareObjectsById} from '../common/utils/utils';
import {ThreadSendingStatus} from '../common/enums/thread-sending-status';
import {ThreadStatus} from '../common/enums/thread-status';
import {ErrorType} from '../common/enums/error-type';
import {DataService} from './api/data.service';
import {ApplicationStateService} from './application-state.service';
import {UserStoreService} from './user-store.service';
import {WfirmaCommunicationService} from './wfirma-communication.service';
import {ApplicationState} from '../common/enums/application-state';
import {AppError} from '../common/classes/app-error';
import {MessageSendingStatus} from '../common/enums/message-sending-status';
import {MessageType} from '../common/enums/message-type';
import {SocketsService} from './sockets.service';
import {SettingsService} from './settings.service';
import {AuthService} from './auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class MainStoreService {

    data$: Subject<Thread[]> = new Subject();
    allMessagesFetched = false;

    private threads: Thread[] = [];
    private firstMessageInDatabaseId: number;
    private packetSize:number;
    private clientCompanyDataTimeout: number;

    constructor(
        private dataService: DataService,
        private applicationStateService: ApplicationStateService,
        private userStoreService: UserStoreService,
        private wfirmaCommunicationService: WfirmaCommunicationService,
        private socketsService: SocketsService,
        private settingsService: SettingsService,
        private authService: AuthService
    ) {
        this.packetSize = this.settingsService.messagePacketSize;
        this.clientCompanyDataTimeout = this.settingsService.clientCompanyDataTimeout;
        this.setSocketMessagesSubscription();
        this.setSocketsConnectionSubscription();
        this.setLoginSubscription();
    }

    private setSocketsConnectionSubscription() {
        this.socketsService.socketsConnected$.asObservable().subscribe(
            connected => {
                if (connected && this.lastThreadInStore) {
                    this.socketsService.joinMessageChannel(this.lastThreadInStore.id);
                }
            }
        );
    }

    private setSocketMessagesSubscription() {
        this.socketsService.messages$.asObservable().subscribe(
            message => {
                if (!this.isMessageInStore(+message.messageId)) {
                    this.loadRemainingMessages();
                }
            }
        );
    }

    private setLoginSubscription() {
        this.authService.isLoggedIn$.subscribe(
            loggedIn => {
                if (!loggedIn) {
                    this.applicationStateService.setError(new AppError(ErrorType.apiLogin, 'Błąd logowania', null));
                    this.broadcastData();
                }
            },
            err => {
                this.applicationStateService.setError(new AppError(ErrorType.internal, 'Błąd logowania', err));
                this.broadcastData();
            }
        );

    }

    private get firstThreadInStore() {
        return this.threads[0];
    }

    get lastThreadInStore() {
        return this.threads.length ? this.threads[this.threads.length - 1] : null;
    }

    get lastOpenThreadInStore() {
        if (!this.threads.length) {
            return null;
        }

        const openThreads = this.threads.filter(thread => thread.status !== ThreadStatus.closed);

        return openThreads ? openThreads[openThreads.length - 1] : null;
    }


    get firstMessageInStoreId() {
        const firstThread = this.firstThreadInStore;

        return firstThread ? firstThread.firstMessageId : null;
    }

    get lastMessageInStoreId() {
        const lastThread = this.lastThreadInStore;

        return lastThread ? lastThread.lastMessageId : null;
    }

    private broadcastData() {
        this.allMessagesFetched = this.firstMessageInDatabaseId === this.firstMessageInStoreId;
        this.data$.next(this.threads.slice());
    }

    private broadcastDataAndState(success = true) {
        this.broadcastData();

        if (success) {
            this.applicationStateService.setState(ApplicationState.READY);
        }
    }

    private isMessageInStore(messageId: number) {
        const lastThread = this.lastThreadInStore;

        if (!messageId || !lastThread) {
            return false;
        }

        // Na tę chwilę owarty może być tylko jeden wątek. Gdyby sie to miało zmienić trzeba przeszukać wszystkie otwarte.
        return lastThread.getMessageById(messageId);
    }

    private sortThreads() {
        this.threads.sort(compareObjectsById);
    }

    private findThreadById(id: number) {
        return this.threads.find(thread => thread.id === id);
    }

    private addOneThreadToStore(newThread: Thread): boolean {
        if (this.findThreadById(newThread.id)) {

            return false;
        }

        this.threads.push(newThread);
        this.sortThreads();

        return true;
    }

    private addThreadsToStore(threads: Thread[]) {
        threads.forEach(thread => this.addOneThreadToStore(thread));
        this.socketsService.joinMessageChannel(this.lastThreadInStore.id);
    }

    private addFileData(message: Message) {
        this.dataService.getMessageAttachment(message.id).subscribe(
            result => {
                try {
                    message.message = result[0].CommonFile.filename;
                    message.fileId = result[0].CommonFile.id;
                    message.fileType = result[0].CommonFile.type;
                    message.sendingStatus = MessageSendingStatus.sent;
                } catch (e) {
                    message.sendingStatus = MessageSendingStatus.error;
                }
            },
            () => message.sendingStatus = MessageSendingStatus.error
        );
    }

    private addMessagesToThreads(messages: Message[], threads: Thread[]) {
        for (const message of messages) {
            if (message.type === MessageType.file) {
                this.addFileData(message);
            }

            for (const thread of threads) {
                if (message.threadId === thread.id) {
                    thread.addMessage(message);
                }
            }
        }
    }

    private createNewMessage(text: string, threadId: number) {
        return new Message(
            -1,
            threadId,
            MessageType.text,
            text,
            false,
            this.userStoreService.client.id,
            MessageSendingStatus.sending
        );
    }

    private postQuestion(message: Message) {
        this.dataService.postMessage(message).subscribe(
            result => {
                message.id = +result.id;
                message.sendingStatus = MessageSendingStatus.sent;
                this.broadcastDataAndState();
            },
            () => {
                message.sendingStatus = MessageSendingStatus.error;
                this.broadcastDataAndState();
            }
        );
    }

    private addQuestionToThread(text: string, thread: Thread) {
        const message = this.createNewMessage(text, thread.id);

        thread.addMessage(message);
        this.broadcastData(); // Optimistic update
        this.postQuestion(message);
    }

    postThreadAndQuestion(thread: Thread, message: Message) {
        merge(this.wfirmaCommunicationService.companyData$, timer(this.clientCompanyDataTimeout).pipe(mapTo('')))
            .pipe(
                take(1),
                switchMap(result => {
                    thread.clientInfo = result.client_info || '';

                    return this.dataService.postThread(thread);
                })
            )
            .subscribe(
                result => {
                    thread.id = +result.id;
                    thread.ownerId = +result.owner_id;
                    message.threadId = thread.id;
                    this.socketsService.joinMessageChannel(thread.id);
                    this.postQuestion(message);
                },
                () => {
                    message.sendingStatus = MessageSendingStatus.error;
                    this.broadcastDataAndState();
                }
            );
    }


    private addThreadAndQuestion(text: string) {
        const
            lastOpenThread = this.lastOpenThreadInStore,
            thread = lastOpenThread ? lastOpenThread : this.createNewThread(),
            message = this.createNewMessage(text, thread.id);

        this.addOneThreadToStore(thread);
        thread.addMessage(message);
        this.broadcastData();  // Optimistic update
        this.wfirmaCommunicationService.getCompanyData();
        this.postThreadAndQuestion(thread, message);
    }

    private getThreadIdsToFetch(messages: Message[]) {
        const result = [],
            threadIds: number[] = Array.from(new Set(messages.map(message => message.threadId)));

        for (const threadId of threadIds) {
            if (!this.threads.find(thread => thread.id === threadId)) {
                result.push(threadId);
            }
        }

        return result;
    }

    private getLoadMessageObservable(fromId: number, order: string, packetSize: number) {
        return this.dataService.getMessages(fromId, order, packetSize).pipe(
            switchMap((messages: Message[]) => {
                if (!messages.length) {

                    return EMPTY;
                }

                const threadIds = this.getThreadIdsToFetch(messages);

                if (!threadIds.length) {
                    this.addMessagesToThreads(messages, this.threads);

                    return EMPTY;
                }

                return this.dataService.getThreads(threadIds).pipe(
                    tap(threads => {
                            this.addThreadsToStore(threads);
                            this.addMessagesToThreads(messages, threads);
                        }
                    )
                );
            })
        );
    }

    resetStore() {
        this.threads = [];
        this.firstMessageInDatabaseId = null;
        this.allMessagesFetched = false;
        this.broadcastData();
    }

    loadMessagesFirstTime() {
        this.resetStore();
        this.applicationStateService.setState(ApplicationState.LOADING);

        this.dataService.getFirstMessageId()
            .pipe(
                tap(firstMessageInDatabaseId => this.firstMessageInDatabaseId = firstMessageInDatabaseId),
                switchMap(firstMessageInDatabaseId => firstMessageInDatabaseId
                    ? this.getLoadMessageObservable(null, 'desc', this.packetSize)
                    : EMPTY // Brak wiadomości w bazie
                )
            )
            .subscribe({
                error: err => this.applicationStateService.setError(new AppError(ErrorType.apiGet, 'Błąd przy pobieraniu wiadomości', err)),
                complete: () => this.broadcastDataAndState()
            });
    }

    loadPreviousMessages() {
        this.applicationStateService.setState(ApplicationState.LOADING_PREVIOUS);

        if (this.firstMessageInStoreId === this.firstMessageInDatabaseId) {
            this.broadcastDataAndState();

            return; // Nie ma nic więcej do pobran
        }

        this.getLoadMessageObservable(this.firstMessageInStoreId, 'desc', this.packetSize).subscribe({
            error: err => this.applicationStateService.setError(new AppError(ErrorType.apiGet, 'Błąd przy pobieraniu wiadomości', err)),
            complete: () => this.broadcastDataAndState()
        });
    }

    loadRemainingMessages() {
        this.getLoadMessageObservable(this.lastMessageInStoreId, 'asc', 9999).subscribe({
            error: err => this.applicationStateService.setError(new AppError(ErrorType.apiGet, 'Błąd przy pobieraniu wiadomości', err)),
            complete: () => this.broadcastDataAndState()
        });
    }

    addQuestion(text: string) {
        const lastThread = this.lastOpenThreadInStore;

        !lastThread || lastThread.status === ThreadStatus.closed || lastThread.id === -1
            ? this.addThreadAndQuestion(text)
            : this.addQuestionToThread(text, lastThread);
    }

    closeThread(threadId: number) {
        this.applicationStateService.setState(ApplicationState.LOADING);

        this.dataService.closeThread(threadId).subscribe(
            () => {
                this.findThreadById(threadId).status = ThreadStatus.closed;
                this.broadcastDataAndState();
            },
            err => this.applicationStateService.setInfoError(new AppError(ErrorType.apiPut, 'Wystąpił błąd przy próbie zamknięcia watku. Prosimy spróbowac jeszcze raz za chwilę', err))
        );
    }

    createNewThread() {
        return new Thread(-1, new Date(), ThreadStatus.active, ThreadSendingStatus.sending, null);
    }

    retrySendMessage(message: Message) {
        const lastThread = this.lastOpenThreadInStore,
            noThread = !lastThread || lastThread.status === ThreadStatus.closed || lastThread.id === -1;

        message.sendingStatus = MessageSendingStatus.sending;

        if (noThread) {
            this.postThreadAndQuestion(lastThread, message);
        } else {
            message.threadId = lastThread.id;
            this.postQuestion(message);
        }
    }
}
