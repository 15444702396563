import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetSettingsInterface} from "../common/interfaces/widget.interface";

@Component({
    selector: 'app-waiting',
    templateUrl: './waiting.component.html',
    styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent implements OnInit {

    @Input() type: 'full' | 'top';
    @Input() styleConfig: WidgetSettingsInterface;

    constructor() {
    }

    ngOnInit() {}
}
