<div class="chat-message" [ngClass]="getStyleClasses()">
    <ng-container *ngIf="message.reply">
        <img *ngIf="sender?.avatar; else defaultAvatarTemplate" [src]="sender.avatar" alt="avatar" class="chat-message__avatar" crossorigin="anonymous">
    </ng-container>
    <div class="chat-message__text" [ngClass]="{'error': !message.fileId,
        'chat-message__text-border': styleConfig.theme === contrastTheme}"
         [ngStyle]="message.reply
         ? {'background-color': styleConfig.content.supportBackground, 'color': styleConfig.content.supportColor, 'font-size': styleConfig.content.fontSize + 'px', 'line-height': '20px'}
         : {'background-color': styleConfig.content.clientBackground, 'color': styleConfig.content.clientColor, 'font-size': styleConfig.content.fontSize + 'px', 'line-height': '20px'}">
        <div
            *ngIf="message.sendingStatus !== sendingStatus.error; else errorTemplate"
            class="chat-message__file"
            [ngClass]="disabled ? 'disabled' : ''"
            (click)="downloadAttachment()">
            <mat-icon [ngStyle]="{'scale': '0.8', 'transform': 'rotate(135deg)'}">{{iconType}}</mat-icon>
            <span class="chat-message__name" >{{message.message}}</span>
        </div>
    </div>
</div>

<ng-template #defaultAvatarTemplate>
    <img src="/assets/images/default-avatar.svg" alt="avatar" class="chat-message__avatar">
</ng-template>

<ng-template #errorTemplate>
    <mat-icon>error</mat-icon>
    <span>{{ 'CAPTIONS.FILE-NAME-ERROR' | translate }}</span>
</ng-template>
