import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'extractTextAfterLink'
})
export class ExtractTextAfterLinkPipe implements PipeTransform {
    transform(value: string, startMarker: string): string | null {
        const startIdx = value.indexOf(startMarker);

        if (startIdx !== -1) {
            const filteredText = value.substring(startIdx + startMarker.length).trim();
            return filteredText.slice(0, -2);
        }

        return null;
    }
}
