import {Message} from './message';
import {compareObjectsById} from '../utils/utils';
import {ThreadStatus} from '../enums/thread-status';
import {ThreadSendingStatus} from '../enums/thread-sending-status';

export class Thread {
    messages: Message[] = [];

    constructor(
        public id: number,
        public dateCreated: Date,
        public status: ThreadStatus,
        public sendingStatus: ThreadSendingStatus,
        public ownerId: number,
        public clientInfo?: string
    ) {
    }

    private sortMessages() {
        this.messages.sort(compareObjectsById);
    }

    get firstMessageId() {
        return this.messages.length ? this.messages[0].id : null;
    }

    get lastMessageId() {
        if (!this.messages.length) {
            return null;
        }

        const savedMessages = this.messages.filter(message => message.id !== -1);

        return savedMessages.length ? savedMessages[savedMessages.length - 1].id : null;
    }

    getMessageById(id: number) {
        return this.messages.find(message => message.id === id);
    }

    addMessage(newMessage: Message) {
        // Dla optimistic updates
        if (newMessage.id === -1) {
            this.messages.push(newMessage);

            return;
        }

        if (this.getMessageById(newMessage.id)) {
            return;
        }

        this.messages.push(newMessage);
        this.sortMessages();
    }
}
