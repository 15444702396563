export const environment = {
    production: true,
    staging: false,
    customer_id: '86',
    restApiUrl: 'https://api.client.5ways.com/rest/',
    apiUrl: 'https://api.client.5ways.com/',
    rootUrl: 'https://api.client.5ways.com/',
    apiClientId: '750ef3366febdb817ab69d93f2be6eb5',
    socketsUrl: 'https://ws.5ways.com',
    // iframeParentDomain: 'https://wfirma.pl',
    iframeParentDomain: '',
    testLogin: '',
    testPassword: '',
    devDebug: false
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
