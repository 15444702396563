import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AvatarService {

    constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {
    }

    blobToString(user, blob) {
        return new Observable(observer => {
            const fileReader = new FileReader();

            fileReader.onload = () => observer.next({
                ...user,
                avatar: this.domSanitizer.bypassSecurityTrustUrl(fileReader.result.toString())
            });
            fileReader.onloadend = () => observer.complete();
            fileReader.onerror = err => observer.error(err);

            fileReader.readAsDataURL(blob);
        });
    }

    addUserAvatar(user: any) {
        const userWithoutAvatar = {
            ...user,
            avatar: ''
        };

        if (+user.has_avatar === 0) {
            return of(userWithoutAvatar);
        }

        return this.getAvatar(user.id).pipe(
            switchMap((blob: Blob) => this.blobToString(user, blob)),
            catchError(() => of(userWithoutAvatar))
        );
    }

    getAvatar(userId): Observable<any> {
        return this.http.get(environment.restApiUrl + 'common_file_user_avatar/' + userId, {responseType: 'blob'});
    }
}
