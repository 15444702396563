import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTranslateService {

  constructor(public translate: TranslateService) { 

  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    console.log('language changed:', lang)
  }
}
