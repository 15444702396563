import {Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, retryWhen} from 'rxjs/operators';
import {AuthTokenInterface} from '../../common/interfaces/oauth-token.interface';
import {ApplicationStateService} from '../application-state.service';
import {ApplicationState} from '../../common/enums/application-state';
import {genericRetryStrategy} from '../../common/utils/retry-strategy';
import {UserCredentials} from '../../common/interfaces/user-credentials';
import {InterceptorSkipHeader} from '../../common/utils/utils';
import {UserStoreService} from "../user-store.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private credentials: UserCredentials;
    private loggedIn = new Subject<boolean>();

    isLoggedIn$ = this.loggedIn.asObservable();

    constructor(private http: HttpClient, private applicationStateService: ApplicationStateService, private userStoreService: UserStoreService) {
    }

    get headers() {
        return {
            'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.getAccessToken()
        };
    }

    getUserId(): number {
        const customerData = this.applicationStateService.customerData();

        return +localStorage.getItem(`${customerData.external_hash}-userId`);
    }

    private setUserId(userId) {
        const customerData = this.applicationStateService.customerData();

        localStorage.setItem(`${customerData.external_hash}-userId`, userId);
    }

    private removeUserId() {
        const customerData = this.applicationStateService.customerData();
        localStorage.removeItem(`${customerData.external_hash}-userId`);
    }

    private removeAccessToken() {
        const customerData = this.applicationStateService.customerData();
        localStorage.removeItem(`${customerData.external_hash}-accessToken`);
        this.loggedIn.next(false);
    }

    private removeWsToken()
    {
        const customerData = this.applicationStateService.customerData();
        localStorage.removeItem(`${customerData.external_hash}-wsToken`);
    }

    private setRefreshToken(refreshToken) {
        const customerData = this.applicationStateService.customerData();
        return localStorage.setItem(`${customerData.external_hash}-refreshToken`, refreshToken);
    }

    private getRefreshToken() {
        const customerData = this.applicationStateService.customerData();
        return localStorage.getItem(`${customerData.external_hash}-refreshToken`);
    }

    private removeRefreshToken() {
        const customerData = this.applicationStateService.customerData();
        localStorage.removeItem(`${customerData.external_hash}-refreshToken`);
    }

    setAccessToken(accessToken) {
        const customerData = this.applicationStateService.customerData();
        localStorage.setItem(`${customerData.external_hash}-accessToken`, accessToken);
        this.loggedIn.next(true);
    }

    getAccessToken() {
        const customerData = this.applicationStateService.customerData();
        return localStorage.getItem(`${customerData.external_hash}-accessToken`);
    }

    setWsToken(wsToken)
    {
        const customerData = this.applicationStateService.customerData();
        localStorage.setItem(`${customerData.external_hash}-wsToken`, wsToken);
    }

    getWsToken()
    {
        const customerData = this.applicationStateService.customerData();
        return localStorage.getItem(`${customerData.external_hash}-wsToken`);
    }

    private _login() {
        this.applicationStateService.setState(ApplicationState.LOADING);

        const creds = {
                grant_type: 'password',
                client_id: this.applicationStateService.customerData().client_id,
                username: this.credentials.login,
                password: this.credentials.password
            },
            headers = new HttpHeaders().set('Content-Type', 'application/json').set(InterceptorSkipHeader, '');

        this.http.post(environment.restApiUrl + 'token', creds, {headers})
            .pipe(retryWhen(genericRetryStrategy()))
            .subscribe((data: AuthTokenInterface) => {
                    if (data['access_token']) {
                        this.setUserId(data.user_id);
                        this.setWsToken(data.ws_token);
                        this.setAccessToken(data.access_token);
                        this.setRefreshToken(data.refresh_token);
                        this.loggedIn.next(true);
                    } else {
                        this.loggedIn.next(false);
                    }
                },
                () => this.loggedIn.next(false)
            );
    }

    login(userCredentials: UserCredentials) {
        try {
            this.credentials = userCredentials;
            this._login();
        } catch (e) {
            this.loggedIn.next(false);
        }
    }

    retryLogin() {
        try {
            this._login();
        } catch (e) {
            this.loggedIn.next(false);
        }
    }

    logout() {
        this.http.delete(environment.restApiUrl + 'token').subscribe();
        this.removeUserId();
        this.removeWsToken();
        this.removeAccessToken();
        this.removeRefreshToken();
    }

    tryLoginByToken() {
        if (!this.getAccessToken()) {
            return false;
        }

        this.loggedIn.next(true);
        this.userStoreService.getChatUserData();
        this.applicationStateService.setState(ApplicationState.READY);

        return true;
    }

    refreshAccessToken(): Observable<any> {
        const creds = {
                grant_type: 'refresh_token',
                refresh_token: this.getRefreshToken(),
                client_id: environment.apiClientId
            },
            headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.http.post(environment.restApiUrl + 'token', creds, {headers}).pipe(
            catchError(err => {
                console.error('Error in refreshAccessToken', err);

                return throwError(err);
            })
        );
    }
}
