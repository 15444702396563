import {Injectable} from '@angular/core';
import {EMPTY, Observable, of, Subject, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {catchError, first, switchMap} from 'rxjs/operators';
import {InterceptorSkipHeader} from '../../common/utils/utils';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService {

    private refreshInProgress = false;

    private refreshSubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private http: HttpClient,
        private authService: AuthService,
    ) {
    }

    public intercept(req: HttpRequest<any>, delegate: HttpHandler): Observable<HttpEvent<any>> {
        const clone: HttpRequest<any> = req.clone();

        if (clone.headers.has(InterceptorSkipHeader)) {
            const headers = clone.headers.delete(InterceptorSkipHeader);

            return delegate.handle(req.clone({headers}));
        }

        return this.request(clone).pipe(
            switchMap((request: HttpRequest<any>) => delegate.handle(request)),
            catchError((res: HttpErrorResponse) => this.responseError(clone, res))
        );
    }

    private request(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        if (this.refreshInProgress && !req.url.includes('token')) {
            return this.delayRequest(req);
        }

        return this.addToken(req);
    }

    private responseError(req: HttpRequest<any>, res: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (!this.refreshInProgress && res.status === 401) {
            this.refreshInProgress = true;

            this.authService
                .refreshAccessToken()
                .subscribe(
                    data => {
                        if (data.access_token) {
                            this.authService.setAccessToken(data.access_token);
                        }

                        this.refreshInProgress = false;
                        this.refreshSubject.next(true);
                    },
                    (_data) => {
                        if (this.refreshInProgress) {
                            this.refreshInProgress = false;
                            this.refreshSubject.next(false);
                            this.authService.logout();

                            return EMPTY;
                        } else {
                            this.refreshInProgress = false;
                            this.refreshSubject.next(false);
                        }
                    }
                );
        }

        if (this.refreshInProgress && res.status === 401) {
            return this.retryRequest(req, res);
        }

        return throwError(res);
    }

    private delayRequest(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        return this.refreshSubject.pipe(
            first(),
            switchMap((status: boolean) => status ? this.addToken(req) : throwError(req))
        );
    }

    private retryRequest(req: HttpRequest<any>, res: HttpErrorResponse): Observable<HttpEvent<any>> {
        return this.refreshSubject.pipe(
            first(),
            switchMap((status: boolean) => status ? this.http.request(req) : throwError(res || req))
        );
    }

    private addToken(req: HttpRequest<any>): Observable<HttpRequest<any>> {
        return of(req.clone({setHeaders: this.authService.headers}));
    }
}
