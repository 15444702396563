<input
    #fileInput
    type="file"
    ngFileSelect
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
    multiple
    style="display: none"
>
<button
        [ngClass]="styleConfig.theme === darkMode ? 'file-uploader__button-dark-mode' : 'file-uploader__button-light-mode'"
        [disabled]="!enabled"
        matTooltip="{{ 'FILE-UPLOADER.ADD-ATTACHMENT' | translate }}"
        type="button"
        (click)="openSaveFileDialog()"
        [ngStyle]="{'background-color': styleConfig.actions.textAreaBackground}"
    >
    <mat-icon [ngStyle]="{'color': styleConfig.actions.attachmentIconColor}">attach_file</mat-icon>
</button>
