<ng-container>
    <mat-card class="form" *ngIf="showForm && activeConfig" [ngClass]="getClasses()" [ngStyle]="{'background-color': activeConfig.content.formBackground}">
        <div>
            <mat-icon
                [ngStyle]="{'color': activeConfig.content.formLinksAndCloseIconColor}"
                [ngClass]="{'display-icon': isFullpageConfig}"
                (click)="close()"
                class="toolbar__close">close</mat-icon>
        </div>
        <h1 [ngStyle]="{'color': activeConfig.header.formLabelColor, 'font-size': activeConfig.header.formLabelFontSize + 'px', 'margin-top': '20px', 'text-align': activeConfig.header.textAlign}" class="form__subtitle">{{activeConfig.header.formLabel}}</h1>
        <h3 [ngStyle]="{'color': activeConfig.header.formSubtitleColor, 'font-size': activeConfig.header.formSubtitleFontSize + 'px', 'text-align': activeConfig.header.textAlign, 'font-weight': '400'}" class="form__subtitle">{{ activeConfig.header.formSubtitle }}</h3>
        <form [formGroup]="widgetFormGroup" (ngSubmit)="onFormDataSubmit()">
            <div class="input-container">
            <textarea
                [ngClass]="isFullpageConfig ? 'fullpage-config' : ''"
                class="message-textarea"
                name="message"
                placeholder="{{
                    activeConfig.content.placeholderText
                    ? activeConfig.content.placeholderText
                    : ('FORM-WIDGET.ENTER-QUESTION' | translate) }}"
                [ngStyle]="{'border-color': activeConfig.content.textareaBorderColor,
                'background-color': activeConfig.content.backgroundColor,
                'color': activeConfig.content.inputsTextColor }"
                type="text"
                formControlName="userMessage">
            </textarea>
                <mat-error id="mat-error-message" *ngIf="formCtrlMsg.invalid && (formCtrlMsg.dirty || formCtrlMsg.touched || dataSubmitted)">
                    <div *ngIf="formCtrlMsg.errors.required">
                        {{'VALIDATION.FIELD-REQUIRED' | translate}}
                    </div>
                </mat-error>
            </div>
            <div [ngClass]="{
                'fullpage-config' : isFullpageConfig,
                'display-all': activeConfig.actions.showPhoneInput && activeConfig.actions.showLastnameInput}"
                 class="input-section">
                <div class="input-container" [ngClass]="{'display-all-fields': activeConfig.actions.showPhoneInput && activeConfig.actions.showLastnameInput}">
                    <fieldset [ngStyle]="{'border-color': activeConfig.content.inputsBorderColor, 'background-color': activeConfig.content.formBackground}">
                        <legend [ngStyle]="{'background-clip': 'content-box', 'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor}">{{ 'FORM-WIDGET.NAME-INPUT' | translate }} <span style="color:red">*</span></legend>
                        <input [ngStyle]="{'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor, 'font-size': '16px'}"
                               type="text"
                               formControlName="userName">
                    </fieldset>
                    <mat-error *ngIf="formCtrlName.invalid && (formCtrlName.dirty || formCtrlName.touched || dataSubmitted)">
                        <div *ngIf="formCtrlName.errors.required">
                            {{'VALIDATION.FIELD-REQUIRED' | translate}}
                        </div>
                    </mat-error>
                </div>
                <div *ngIf="activeConfig.actions.showLastnameInput" class="input-container" [ngClass]="{'display-all-fields': activeConfig.actions.showPhoneInput && activeConfig.actions.showLastnameInput}">
                    <fieldset [ngStyle]="{'border-color': activeConfig.content.inputsBorderColor, 'background-color': activeConfig.content.formBackground}">
                        <legend [ngStyle]="{'background-clip': 'content-box', 'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor}">{{ 'FORM-WIDGET.LASTNAME-INPUT' | translate }} <span style="color:red">*</span></legend>
                        <input [ngStyle]="{'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor, 'font-size': '16px'}"
                               type="text"
                               formControlName="userLastname">
                    </fieldset>
                    <mat-error *ngIf="formCtrlLastname.invalid && (formCtrlLastname.dirty || formCtrlLastname.touched || dataSubmitted)">
                        <div *ngIf="formCtrlLastname.errors.required">
                            {{'VALIDATION.FIELD-REQUIRED' | translate}}
                        </div>
                        <div *ngIf="formCtrlLastname.errors.pattern && !formCtrlLastname.errors.required">
                            {{'VALIDATION.INVALID-FORMAT' | translate}}
                        </div>
                    </mat-error>
                </div>
                <div class="input-container" [ngClass]="{'display-all-fields': activeConfig.actions.showPhoneInput && activeConfig.actions.showLastnameInput}">
                    <fieldset [ngStyle]="{'border-color': activeConfig.content.inputsBorderColor, 'background-color': activeConfig.content.formBackground}">
                        <legend [ngStyle]="{'background-clip': 'content-box', 'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor}">{{ 'FORM-WIDGET.EMAIL-INPUT' | translate }} <span style="color:red">*</span></legend>
                        <input [ngStyle]="{'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor, 'font-size': '16px'}"
                               type="email"
                               formControlName="userEmail">
                    </fieldset>
                    <mat-error *ngIf="formCtrlEmail.invalid && (formCtrlEmail.dirty || formCtrlEmail.touched || dataSubmitted) ">
                        <div *ngIf="formCtrlEmail.errors.required">
                            {{'VALIDATION.FIELD-REQUIRED' | translate}}
                        </div>
                        <div *ngIf="formCtrlEmail.errors.email && !formCtrlEmail.errors.required">
                            {{'VALIDATION.INCORRECT-EMAIL' | translate}}
                        </div>
                    </mat-error>
                </div>
                <div *ngIf="activeConfig.actions.showPhoneInput" class="input-container" [ngClass]="{'display-all-fields': activeConfig.actions.showPhoneInput && activeConfig.actions.showLastnameInput}">
                    <fieldset [ngStyle]="{'border-color': activeConfig.content.inputsBorderColor, 'background-color': activeConfig.content.formBackground}">
                        <legend [ngStyle]="{'background-clip': 'content-box', 'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor}">{{ 'FORM-WIDGET.PHONE-INPUT' | translate }} <span style="color:red">*</span></legend>
                        <input [ngStyle]="{'background-color': activeConfig.content.formBackground, 'color': activeConfig.content.inputsTextColor, 'font-size': '16px'}"
                               type="text"
                               formControlName="userPhone">
                    </fieldset>
                    <mat-error *ngIf="formCtrlPhone.invalid && (formCtrlPhone.dirty || formCtrlPhone.touched || dataSubmitted)">
                        <div *ngIf="formCtrlPhone.errors.required">
                            {{'VALIDATION.FIELD-REQUIRED' | translate}}
                        </div>
                        <div *ngIf="formCtrlPhone.errors.pattern && !formCtrlPhone.errors.required">
                            {{'VALIDATION.INVALID-FORMAT' | translate}}
                        </div>
                    </mat-error>
                </div>
            </div>

            <ng-container *ngIf="activeConfig.actions.showAdditionalTerm">
                <div *ngFor="let term of activeConfig.content.customUserTerms" class="register-box">
                    <input class="mt-10" type="checkbox" [class.checkbox-error]="!termCheckboxValue.value && dataSubmitted" formControlName="termCheckboxAccept" required/>
                    <div class="mt-10 register-box__term" [ngClass]="{'register-box__term-mobile-size': isMobile}" *ngIf="term" [innerHtml]="transformQuillData(term)"></div><span style="color:red">*</span>
                </div>
            </ng-container>

            <div class="five-ways-terms">
                <div class="error-checkbox"></div>
                <div>
                    {{ 'FORM-WIDGET.5WAYS-TERMS-ACCEPTATION' | translate }}
                    <a target="_blank" rel="noreferrer noopener" href="https://support.5ways.com/-polityka-prywatnosci" class="privacy-link" [ngStyle]="{'color': activeConfig.content.formLinksAndCloseIconColor}">
                        {{ 'FORM-WIDGET.5WAYS-PRIVACY-LINK' | translate }}</a>, <a target="_blank" rel="noreferrer noopener" href="https://support.5ways.com/-polityka-cookies" class="privacy-link" [ngStyle]="{'color': activeConfig.content.formLinksAndCloseIconColor}">
                    {{ 'FORM-WIDGET.5WAYS-COOKIES' | translate }}</a>.
                </div>
            </div>

            <button class="send-btn"
                    type="submit"
                    [ngStyle]="{'font-size': activeConfig.actions.fontSizeButton + 'px',
                            'color': activeConfig.actions.sendBtnLabelColor,
                            'background': activeConfig.onInit.buttonBackground === '#309a00'
                            ? 'linear-gradient(to right, #309a00, #3cc300)'
                            : activeConfig.onInit.buttonBackground}">
                {{ 'FORM-WIDGET.SEND-BTN' | translate }}
            </button>
        </form>
    </mat-card>
    <mat-card *ngIf="showSuccessMsg" class="form__message">
        <div>
            <mat-icon
                [ngStyle]="{'color': activeConfig.content.formLinksAndCloseIconColor}"
                [ngClass]="{'display-icon': isFullpageConfig}"
                (click)="close()" class="toolbar__close">close</mat-icon>
        </div>
        <div class="msg-header">
            <h3 class="msg-text">{{successMsg_header}}</h3>
            <h3 class="form__subtitle-msg">{{successMsc_subheader}}</h3>
        </div>
    </mat-card>
    <mat-card *ngIf="showErrorMsg" class="form__message">
        <div class="msg-header">
            <h3 class="msg-text">{{successMsg_header}}</h3>
            <button class="send-btn" (click)="showFormField()">{{errorBtnMsg}}</button>
        </div>
    </mat-card>
</ng-container>
