<div class="welcome-box" [ngClass]="promptUserName ? 'full' : 'short'" [ngStyle]="{'background-color': styleConfig.actions.textAreaBackground}">
    <ng-container *ngIf="promptUserName; else welcomeTemplate">
        <span class="welcome-box__text"
            [ngStyle]="{'font-size': styleConfig.onInit.fontText + 'px',
            'color': styleConfig.onInit.textColor}">
                {{ styleConfig.onInit.text || 'WELCOME-BOX.ON-INIT-TEXT' | translate}}
        </span>
        <input *ngIf="styleConfig.onInit.requiredSetFirstName"
            [ngStyle]="{ 'color': styleConfig.onInit.textColor,
            'background-color': styleConfig.content.backgroundColor,
            'border-color': styleConfig.content.backgroundColor}"
            placeholder="{{
            styleConfig.onInit.inputPlaceholder
            ? styleConfig.onInit.inputPlaceholder
            : 'WELCOME-BOX.PROVIDE-NAME' | translate }}"
            class="welcome-box__input"
            [ngClass]="styleConfig.theme === darkTheme ? 'welcome-box__input-dark' : 'welcome-box__input-light'"
            maxlength="32"
            type="text"
            [(ngModel)]="newName">
        <button class="welcome-box__button"
            [ngStyle]="{'font-size': styleConfig.onInit.buttonFontSize + 'px',
            'background': styleConfig.onInit.buttonBackground === '#309a00'
            ? 'linear-gradient(to right, #309a00, #3cc300)'
            : styleConfig.onInit.buttonBackground,
            'color': styleConfig.onInit.buttonColorText}"
            (click)="createNewUnregisteredUser()"
            [disabled]="styleConfig.onInit.requiredSetFirstName ? newName.length < 1 : false">
            {{ styleConfig.onInit.buttonLabel || 'WELCOME-BOX.START' | translate }}
        </button>
        <app-footer [styleConfig]="styleConfig"></app-footer>
    </ng-container>

    <!-- Mat Select created for testing purposes
    <mat-form-field>
        <mat-label>Select a language</mat-label>
        <mat-select
            disableRipple
            (selectionChange)="changeLanguage($event.value)">
          <mat-option
            *ngFor="let language of languages"
            [value]="language">
                {{language}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    Mat Select created for testing purposes -->
</div>

<ng-template #welcomeTemplate>
    <span class="welcome-box__text">{{ 'WELCOME-BOX.WELCOME' | translate }}</span>
    <br>
    <span>{{ 'CAPTIONS.WELCOME-MESSAGE_NONAME' | translate }}</span>
</ng-template>



