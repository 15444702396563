import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, pluck, retryWhen, shareReplay} from 'rxjs/operators';
import {DataResponseInterface} from '../../common/interfaces/data-response-interface';
import {genericRetryStrategy} from '../../common/utils/retry-strategy';
import {ApplicationStateService} from '../application-state.service';
import {InterceptorSkipHeader} from '../../common/utils/utils';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(private http: HttpClient, private _applicationStateService: ApplicationStateService) {
    }

    getEmployee(id: number) {
        return this.http.get<{ UserEmployee: any }>(environment.restApiUrl + 'user_employee/' + id).pipe(
            pluck('UserEmployee'),
            map(employee => {
                return {
                    ...employee,
                    avatar: ''
                }
            })
        );
    }

    getUser(userId?: number): Observable<any> {
        if (!+userId) {
            return EMPTY;
        }

        return this.http.get(environment.restApiUrl + 'user/' + userId).pipe(
            pluck('User'),
        );
    }

    getChatUser(): Observable<any> {
        return this.http.get<DataResponseInterface>(environment.restApiUrl + 'chat_user/').pipe(
            map(result => result.results[0].ChatUser)
        );
    }

    getChatUserId(): Observable<number> {
        return this.getChatUser().pipe(
            map(user => +user.assign_user_id),
            shareReplay(1)
        );
    }

    saveChatUserName(id: string, name: string) {
        const body = {
            ChatUser: {
                user_name: name
            }
        };

        return this.http.put(environment.restApiUrl + 'chat_user/' + id, JSON.stringify(body)).pipe(
            retryWhen(genericRetryStrategy())
        );
    }

    private _generateUniqueEmailAddress(name) {
        const characters = '0123456789abcdefghijklmnopqrstuvwxyz',
            domain = 'respondo.pl';

        let uniqueString = '';

        for (let i = 0; i < 8; i++) {
            let randomIndex = Math.floor(Math.random() * characters.length);
            uniqueString += characters[randomIndex];
        }

        name = name.trim().toLowerCase().replace(/[^a-zA-Z0-9]/g, '');

        return `${name}.${uniqueString}@${domain}`;
    }

    public transformName(name) {
        name = name.trim().toLowerCase().replace(/[^a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, '');

        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    public createNewChatUser(name): Observable<any> {
        const customerData = this._applicationStateService.customerData(),
            formData = new FormData();

        // generuje unikalny adres email w domenie respondo.pl wymagany do rejestracji
        formData.append('data[User][email]', this._generateUniqueEmailAddress(name));
        formData.append('data[User][firstname]', this.transformName(name));
        formData.append('data[User][customer_id]', customerData.customer_id);
        formData.append('data[User][client_id]', customerData.client_id);
        formData.append('data[User][external_id]', '0');

        return this.http.post(environment.rootUrl + 'users/registerChatUser', formData, {headers: new HttpHeaders().set(InterceptorSkipHeader, '')});
    }
}
