import {Injectable} from '@angular/core';
import {UsersService} from './api/users.service';
import {Observable, of, Subject} from 'rxjs';
import {catchError, filter, finalize, map, retryWhen, shareReplay, switchMap} from 'rxjs/operators';
import {ApplicationStateService} from './application-state.service';
import {AvatarService} from './avatar.service';
import {AppError} from '../common/classes/app-error';
import {ErrorType} from '../common/enums/error-type';
import {genericRetryStrategy} from '../common/utils/retry-strategy';

@Injectable({
    providedIn: 'root'
})
export class UserStoreService {
    chatUser$: Subject<any> = new Subject();

    client: {
        id: number,
        name: string,
        avatar: string
    };

    unknownUser = {
        name: 'Nieznany',
        avatar: ''
    };

    users: {id: number, data: Observable<any>}[] = [];

    constructor(
        private usersService: UsersService,
        private applicationStateService: ApplicationStateService,
        private avatarService: AvatarService
    ) {
        this.resetStore();
    }

    private getDisplayName(user) {
        return user.firstname + (user.lastname ? ' ' + user.lastname : '');
    }

    private getUserFromStore(id: number) {
        return this.users.find(user => +user.id === +id);
    }

    private getEmployee(id: number) {
        return this.usersService.getEmployee(id).pipe(
            map(employee => ({
                    ...employee,
                    name: this.getDisplayName(employee)
                })
            ),
            switchMap(employee => this.avatarService.addUserAvatar(employee)),
            catchError(() => of(this.unknownUser)),
            shareReplay(1)
        );
    }

    private getUserData(id: number) {
        return id === this.client.id ? of(this.client) : this.getEmployee(id);
    }

    resetStore() {
        this.users = [];
        this.client = {
            id: null,
            name: '',
            avatar: ''
        };
    }

    loadClientData(clientId) {
        this.client.id = +clientId;
        this.usersService.getUser(+clientId)
            .pipe(retryWhen(genericRetryStrategy()))
            .subscribe(
                user => {
                    this.client.name = this.getDisplayName(user);
                },
                err => this.applicationStateService.setError(new AppError(ErrorType.apiGet, 'Błąd pobierania danych o użytkowniku', err))
            );
    }

    getUser(id: number): Observable<any> {
        const userInStore = this.getUserFromStore(id);

        if (userInStore) {
            return userInStore.data;
        }

        const data = this.getUserData(id);

        this.users.push({id, data});

        return data;
    }

    getChatUserData() {
        this.usersService.getChatUser()
            .pipe(filter(user => !!user))
            .subscribe(
                user => {
                    this.chatUser$.next({
                        userName: user.user_name,
                        userId: user
                    });
                },
                err => this.applicationStateService.setError(new AppError(ErrorType.apiGet, 'Błąd pobierania danych o użytkowniku', err))
            );
    }

    saveChatUser(userId: string, name: string) {
        this.usersService.saveChatUserName(userId, name)
            .pipe(finalize(() => this.getChatUserData()))
            .subscribe({
                error: err => this.applicationStateService.setInfoError(new AppError(ErrorType.apiPut, 'Nie udało się zapisać imienia', err))
            });
    }
}
