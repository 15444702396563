export interface WidgetInterface {
    settings: WidgetSettingsInterface;
    widgetType: WidgetType;
    screenWidth?: number;
    screenHeight?: number;
}

export enum WidgetType {
    Chat = 'chat',
    Form = 'form',
    Poll = 'poll',
    FullpageForm = 'fullpageform'
}

export interface WidgetApiResponse {
    total: number;
    links?: {
        self: string;
        first: string;
        previous: string;
        next: string;
        last: string;
    };
    results: Array<{ChatWidget: ChatWidget}>;
}


export interface ChatWidget {
    id: string;
    external_hash: string;
    url: string;
    type: string;
    settings: string;
    created: string;
    modified: string;
    customer_id: string;
    is_active: string;
}

export interface UserCustomTerm {
    id: number;
    content: string;
}

export interface WidgetSettingsInterface {
    actions: ActionsWidgetInterface;
    button: ButtonOpenWidgetInterface;
    content: ContentWidgetInterface;
    header: HeaderWidgetInterface;
    id: number;
    name: string;
    onInit: OnInitWidgetInterface;
    theme: string;
    uid: string;
}

export interface ActionsWidgetInterface {
    buttonBackground: string;
    fontSizeButton: number;
    attachmentIconColor: string;
    actionsBackground: string;
    textAreaBackground: string;
    buttonLabelColor: string;
    showAttachmentIcon: boolean;
    color?: string;
    showPhoneInput: boolean;
    showLastnameInput: boolean;
    sendBtnLabelColor: string;
    defaultButtonBackground: string;
    showAdditionalTerm: boolean;
}

export interface ButtonOpenWidgetInterface {
    iconSvg?: string;
    formIconSvg?: string;
    radius: number;
    positionOnPage: {right: number; top: number};
    background: string;
    color: string;
    label?: string;
    size: number;
    fontSize: number;
}

export interface ContentWidgetInterface {
    textareaBorderColor: string;
    placeholderText: string;
    backgroundColor: string;
    supportBackground: string;
    clientBackground: string;
    fontSize: number;
    contentBackground: string;
    supportColor: string;
    clientColor: string;
    termsAndConditionsLink: string;
    privacyStatementLink: string;
    inputsTextColor: string;
    inputsBorderColor: string;
    formLinksAndCloseIconColor: string;
    placeholderTextColor: string;
    formBackground: string;
    customUserTerms: UserCustomTerm[];
}

export interface HeaderWidgetInterface {
    formLabel: string;
    chatLabel: string;
    background: string;
    labelColor: string;
    iconColor: string;
    labelSize: number;
    formSubtitle: string;
    formLabelColor: string;
    formLabelFontSize: number;
    formSubtitleColor: string;
    formSubtitleFontSize: number;
    textAlign: string;
}

export interface OnInitWidgetInterface {
    text: string;
    logo: string;
    logoSrc?: string;
    requiredRegister: boolean;
    requiredSetFirstName: boolean;
    textColor: string;
    fontText: number;
    buttonBackground: string;
    buttonColorText: string;
    buttonFontSize: number;
    autoOpenChat: boolean;
    autoOpenChatDelay: number;
    showTooltipMessage?: boolean;
    tooltipMessageDelay?: number;
    tooltipMessageText?: string;
    tooltipBackground?: string;
    tooltipTextColor?: string;
    inputPlaceholder?: string;
    buttonLabel: string;
    footerBackground: string;
}
