import {Injectable} from '@angular/core';

// TODO zrobić zasob settings i pobierać ustawienia
@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    messagePacketSize = 10;
    // fixme zwiększenie czasu może powodować tworzenie nowych wątków w trakcie wysyłania wiadomości
    clientCompanyDataTimeout = 0;
    isReplyingTimeout = 5000;
    infoErrorTimeout = 6000;
    fileUploaderOptions = {
        concurrency: 1,
        maxUploads: 3,
        maxFileSize: 1000 * 1000 * 16
    };

    allowedFileContentTypes = [
        'png',
        'jpeg',
        'jpg',
        'zip',
        'xml',
        'csv',
        'txt',
        'pdf',
        'doc',
        'docx',
        'odt',
        'xls',
        'xlsx',
        'rar',
        'ods',
        'rtf',
        '7z',
        'tiff'
    ];

    colorModes = {
        normal: [
            {key: '--color-background', value: '#fff'},
            {key: '--color-text', value: '#000'},
            {key: '--color-text-inverted', value: '#fff'},
            {key: '--color-primary', value: '#1E85CC'},
            {key: '--color-primary-lighter', value: '#6BC0FB'},
            {key: '--color-question-background', value: '#F4F4F4'},
            {key: '--color-error', value: '#EF5A40'},
            {key: '--color-error-lighter', value: '#EF8A40'},
            {key: '--color-border', value: '#F2F2F2'},
            {key: '--color-toolbar-background', value: '#F2F2F2'},
            {key: '--color-boxshadow', value: '#00000029'},
        ],
        night: [
            {key: '--color-background', value: '#4A4A4A'},
            {key: '--color-text', value: '#F7F9FF'},
            {key: '--color-placeholder', value: '#B4BCC4'},
            {key: '--color-text-inverted', value: '#F7F9FF'},
            {key: '--color-primary', value: '#E48406'},
            {key: '--color-primary-lighter', value: '#BD5E36'},
            {key: '--color-question-background', value: '#777777'},
            {key: '--color-boxshadow', value: '#00000029'},
            {key: '--color-toolbar-background', value: '#424141'},
            {key: '--color-error', value: '#EF5A40'},
            {key: '--color-error-lighter', value: '#EF8A40'},
            {key: '--color-border', value: '#F2F2F2'},
        ],
        'night-blue': [
            {key: '--color-background', value: '#32384D'},
            {key: '--color-text', value: '#F7F9FF'},
            {key: '--color-text-inverted', value: '#fff'},
            {key: '--color-primary', value: '#0F6BAA'},
            {key: '--color-primary-lighter', value: '#2A93DB'},
            {key: '--color-question-background', value: '#202333'},
            {key: '--color-error', value: '#FF7612'},
            {key: '--color-error-lighter', value: '#FF401F'},
            {key: '--color-border', value: '#F7F9FF'},
            {key: '--color-toolbar-background', value: '#292E40'},
            {key: '--color-boxshadow', value: '#000000E6'},
        ],
        contrast: [
            {key: '--color-background', value: '#fff'},
            {key: '--color-text', value: '#000'},
            {key: '--color-text-inverted', value: '#fff'},
            {key: '--color-primary', value: '#0F6BAA'},
            {key: '--color-primary-lighter', value: '#2A93DB'},
            {key: '--color-question-background', value: '#F4F4F4'},
            {key: '--color-error', value: '#EF5A40'},
            {key: '--color-error-lighter', value: '#EF8A40'},
            {key: '--color-border', value: '#000'},
            {key: '--color-toolbar-background', value: '#DBDBDB'},
            {key: '--color-boxshadow', value: '#00000029'}
        ]
    };


    captions = {
        'app-title': 'Czat',
        'btn-send': 'WYŚLIJ',
        'btn-resend': 'Spróbuj ponownie',
        'welcome-message': 'Masz pytanie? Napisz do nas!',
        'welcome-message_noname': 'Wprowadź swoje imię, usprawni to komunikację między Tobą a naszym ekspertem',
        'sending-status_sending': 'Trwa wysyłanie',
        'sending-status_sent': 'Wysłana',
        'sending-status_error': 'Błąd wysyłania. Wyślij ponownie.',
        'sending-status_received': 'Odczytana',
        'thread-closed': 'Wątek zamknięty',
        'file-name-error': 'Błąd pobierania pliku'
    };

    getColorModeProperties(modeName: string) {
        return this.colorModes[modeName];
    }

    constructor() {
    }
}
