import {Component, OnInit} from '@angular/core';
import {FormWidgetService} from '../services/form-widget.service';
import {ConfigStyleService} from '../services/config-style.service';
import {ApplicationStateService} from '../services/application-state.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-fullpage-form',
  templateUrl: './fullpage-form.component.html',
  styleUrls: ['./fullpage-form.component.scss']
})
export class FullpageFormComponent implements OnInit {

    widgetId: string;
    fullpageSettings;
    constructor(private formWidgetService: FormWidgetService,
                private configStyleService: ConfigStyleService,
                private applicationStateService: ApplicationStateService,
                private titleService: Title) {
    }

    ngOnInit() {
        const queryParams = new URLSearchParams(window.location.search);
        this.widgetId = queryParams.get('uuid');
        this.getFormWidgetSettings(this.widgetId);

        this.applicationStateService.customerData
            .mutate((state: { client_id: string, customer_id: string, external_hash: string }): void => {
                state.customer_id = this.widgetId.split('_')[0];
                state.external_hash = this.widgetId.split('_')[1];
            });
    }

    getFormWidgetSettings(widgetId) {
        this.formWidgetService.getFormWidgetSettings(widgetId).subscribe(
            widgetSettings => {
                this.fullpageSettings = JSON.parse(widgetSettings.settings);
                this.titleService.setTitle(this.fullpageSettings.header.formLabel.length ? this.fullpageSettings.header.formLabel : 'Formularz');
            }
        );
    }

}
