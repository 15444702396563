import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {MainStoreService} from '../../services/main-store.service';
import {ApplicationState} from '../../common/enums/application-state';
import {ApplicationStateService} from '../../services/application-state.service';
import {ThreadStatus} from '../../common/enums/thread-status';
import {MessageType} from '../../common/enums/message-type';
import {Message} from '../../common/classes/message';
import {TranslateService} from '@ngx-translate/core';
import {WidgetSettingsInterface} from "../../common/interfaces/widget.interface";
import {ChatTheme} from "../../common/enums/chat-theme";

@Component({
    selector: 'app-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss']
})

export class ChatListComponent implements OnChanges, AfterViewInit, AfterViewChecked {
    @Input() threadsWithMessages: any[];
    @Input() employeeTyping = false;
    @Input() styleConfig: WidgetSettingsInterface;
    @Input() screenWidth: number;

    @Output() loadPrevious: EventEmitter<number> = new EventEmitter();
    @Output() loadNext: EventEmitter<null> = new EventEmitter();
    @Output() retryMessage: EventEmitter<Message> = new EventEmitter();

    @ViewChild('messageList') private messageList: ElementRef;

    SCROLL_LOAD_POSITION = 50;
    loading: false | 'previous' | 'next' = 'next';
    doScroll: false | 'top' | 'bottom' = false;
    firstTimeScrollBottom = true;
    lastScrollPosition: number;
    appState = ApplicationState;
    threadStatus = ThreadStatus;
    messageType = MessageType;
    darkTheme = ChatTheme.dark;
    currentAvatar: string;

    constructor(
        private mainStoreService: MainStoreService,
        public applicationStateService: ApplicationStateService,
        public translate: TranslateService) {
    }

    ngAfterViewInit() {
        if (this.threadsWithMessages.length) {
            this.messageList.nativeElement.scrollBehavior = 'smooth';
            this.lastScrollPosition = Number.MAX_SAFE_INTEGER;
            this.scrollToBottom();
        }
    }

    ngAfterViewChecked() {
        if (!this.threadsWithMessages.length || !this.doScroll) {
            return;
        }

        if (this.doScroll === 'bottom') {
            this.scrollToBottom();
        } else if (this.doScroll === 'top') {
            this.scrollToEnableLoadMore();
        }

        this.doScroll = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['threadsWithMessages'] && changes['threadsWithMessages'].currentValue) {
            if (this.loading === 'previous') {
                this.lastScrollPosition = 0;

                if (!this.mainStoreService.allMessagesFetched) {
                    this.doScroll = 'top';
                }
            } else {
                this.lastScrollPosition = Number.MAX_SAFE_INTEGER;
                this.doScroll = 'bottom';
            }

            this.loading = false;
        } else if (changes['employeeTyping'] && changes['employeeTyping'].currentValue && this.messageList.nativeElement.scrollTop > 60) {
            this.scrollToBottom();
        }
    }

    private checkScrollDirectionUp(position: number) {
        const result = position < this.lastScrollPosition;

        this.lastScrollPosition = position;

        return result;
    }

    private scrollToBottom() {
        let timeout = 200;

        if (this.firstTimeScrollBottom) {
            timeout = 1000;
            this.firstTimeScrollBottom = false;
        }

        setTimeout(() => this.messageList.nativeElement.scrollTop = this.messageList.nativeElement.scrollHeight, timeout);
    }

    private scrollToEnableLoadMore() {
        setTimeout(() => this.messageList.nativeElement.scrollTop = 300, 500);
    }

    private loadPreviousMessages() {
        this.loadPrevious.emit();
    }

    private reachedTop(scrollbarPosition) {
        return scrollbarPosition < this.SCROLL_LOAD_POSITION;
    }

    onScroll(event: any) {
        if (this.loading) {
            return;
        }

        const scrollbarPosition = +event.target.scrollTop,
            scrollUP = this.checkScrollDirectionUp(scrollbarPosition),
            reachedTop = this.reachedTop(scrollbarPosition);

        if (scrollUP && reachedTop) {
            this.loading = 'previous';
            this.loadPreviousMessages();
        }
    }

    get isMobile(): boolean {
        return this.screenWidth <= 450;
    }

    setUserAvatar(avatar: string) {
        this.currentAvatar = avatar;
    }
}
